import { h } from 'preact'


export function moveToSubtopic(draggingItem, targetItem, topics, changes) {
  console.log('Moving item to be a subtopic of target item')
  if (!targetItem.subtopics) targetItem.subtopics = []

  if (!targetItem.subtopics.some(item => item.uid === draggingItem.uid)) {
    const oldParent = findParentById(topics, draggingItem.parent_uid)
    if (oldParent) {
      oldParent.subtopics = oldParent.subtopics.filter(item => item.uid !== draggingItem.uid)
      updateOrderIndices(oldParent.subtopics)
    }

    draggingItem.parent_uid = targetItem.uid
    targetItem.subtopics.push(draggingItem)

    // Move subtopics of draggingItem to root level
    if (draggingItem.subtopics) {
      draggingItem.subtopics.forEach(subtopic => {
        subtopic.parent_uid = null
        topics.push(subtopic)
        changes.push({
          uid: subtopic.uid,
          parent_uid: null,
          order_index: topics.length - 1,
        })
      })
      draggingItem.subtopics = []
    }

    changes.push({
      uid: draggingItem.uid,
      parent_uid: targetItem.uid,
      order_index: targetItem.subtopics.length - 1,
    })

    updateOrderIndices(targetItem.subtopics)
  }
}

export function moveAsSibling(draggingItem, targetItem, topics, changes){
  console.log('Moving item as a sibling of target item')
  const oldParent = findParentById(topics, draggingItem.parent_uid)
  const targetParent = findParentById(topics, targetItem.parent_uid) ||
  (targetItem.parent_uid === null ? { subtopics: topics } : null)

  if (oldParent) {
    oldParent.subtopics = oldParent.subtopics.filter(item => item.uid !== draggingItem.uid)
    updateOrderIndices(oldParent.subtopics)
  }

  if (targetParent && targetParent !== topics) {
    const targetIndex = targetParent.subtopics.findIndex(item => item.uid === targetItem.uid)
    targetParent.subtopics.splice(targetIndex + 1, 0, draggingItem)
    updateOrderIndices(targetParent.subtopics)

    changes.push(...targetParent.subtopics.map((item, index) => ({
      uid: item.uid,
      parent_uid: targetParent.uid,
      order_index: index,
    })))
  } else {
    // Reorder root level topics
    const draggingIndex = topics.findIndex(topic => topic.uid === draggingItem.uid)
    const targetIndex = topics.findIndex(topic => topic.uid === targetItem.uid)
    topics.splice(draggingIndex, 1)
    topics.splice(targetIndex + 1, 0, draggingItem)
    updateOrderIndices(topics)

    changes.push(...topics.map((item, index) => ({
      uid: item.uid,
      parent_uid: null,
      order_index: index,
    })))
  }
}
export function moveToRoot(draggingItem, topics, changes) {
  console.log('Moving item to root')
  const oldParent = findParentById(topics, draggingItem.parent_uid)
  if (oldParent) {
    oldParent.subtopics = oldParent.subtopics.filter(item => item.uid !== draggingItem.uid)
    updateOrderIndices(oldParent.subtopics)
  }

  draggingItem.parent_uid = null
  topics.push(draggingItem)

  changes.push({
    uid: draggingItem.uid,
    parent_uid: null,
    order_index: topics.length - 1,
  })

  updateOrderIndices(topics)
}

export function updateOrderIndices(items) {
  items.forEach((item, index) => {
    item.order_index = index
    if (item.subtopics) {
      updateOrderIndices(item.subtopics)
    }
  })
}

export function applyChanges(takeAction, changes, organizationApikey) {
  changes.forEach(change => {
    if (change.uid) {
      console.log('Applying change:', change)
      takeAction('topics.updateTopicOrder', organizationApikey, change.uid, change)
    } else {
      console.error('Error: topicUid is required', change)
    }
  })
}

export function findItemById(items, id) {
  for (let item of items) {
    if (item.uid === id) return item
    if (item.subtopics) {
      const found = findItemById(item.subtopics, id)
      if (found) return found
    }
  }
  return null
}

export function findParentById(items, id) {
  for (let item of items) {
    if (item.subtopics && item.subtopics.some(subtopic => subtopic.uid === id)) {
      return item
    }
    if (item.subtopics) {
      const found = findParentById(item.subtopics, id)
      if (found) return found
    }
  }
  return null
}

export function findTopicOrSubtopic(topics, uid) {
  for (const topic of topics) {
    if (topic.uid === uid) {
      return topic
    }
    if (topic.subtopics) {
      const subtopic = topic.subtopics.find(sub => sub.uid === uid)
      if (subtopic) {
        return subtopic
      }
    }
  }
  return null
}
