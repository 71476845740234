// InviteNonMembersModal.js

import { h } from 'preact'
import PropTypes from 'prop-types'
import Modal from 'components/Modal' // Assuming you have a Modal component
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'

const InviteNonMembersModal = ({ onConfirm, onCancel }) => (
  <Modal onClose={onCancel}>
    <h2>Invite Non-Members to the Hub</h2>
    <p>Some recipients are not members of this hub. Would you like to invite them?</p>
    <ButtonRow>
      <Button type="normal" onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={onConfirm}>Yes, Invite</Button>
    </ButtonRow>
  </Modal>
)

InviteNonMembersModal.propTypes = {
  missingRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default InviteNonMembersModal
