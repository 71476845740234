import { h } from 'preact'
import Button from 'components/Button'
import EndUserAvatar from 'components/EndUserAvatar'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useAppState } from 'lib/appState'
// import { useAppState } from 'lib/appState'


export function ThreadRepliesNumber({ replyCount, replies, fromMe, openThreadRepliesModal, chatChannelUid, uid }) {

  const publicProfileDids = replies.reduce((acc, reply) => {
    acc.add(reply.creatorUserDid)
    return acc
  }
  , new Set())

  const isThereNewReplies = (replies || []).findIndex(reply => !reply.readAt) !== -1

  const { publicProfiles } = usePublicProfiles(
    [...publicProfileDids],
    'ChatMessage'
  )


  const { takeAction } = useAppState(
    {
      [
      `chat:channel:${chatChannelUid}:thread:open`
      ]: 'chatThread'
    },
    'ThreadRepliesNumber'
  )


  return <div className="OrganizationChatPage-Bubble-ThreadRepliesNumber">
    {
      publicProfiles && publicProfiles.length > 0 &&
      <div className={`OrganizationChatPage-Bubble-ThreadRepliesNumber-avatars ${fromMe ? 'fromMe' : ''}`}>
        {publicProfiles.slice(0, 3).map((publicProfile, i) => {
          return <EndUserAvatar
            key={i}
            publicProfile={publicProfile}
            size="tn"
          />
        })}
        <Button className="OrganizationChatPage-Bubble-ThreadRepliesNumber-avatars-count"
          onClick={() => {
            openThreadRepliesModal()
            takeAction('chat.openThread', chatChannelUid, uid)
          }}
        >
          <div>
            {replyCount} {replyCount === 1 ? 'reply' : 'replies'} {' '}
            {isThereNewReplies && <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: 'var(--tru-blue)',
                borderRadius: '50%',
                display: 'inline-block',
              }} />
            }
          </div>
        </Button>
      </div>}
  </div>
}
