import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import Header from 'components/Header'
import TimeAgo from 'components/TimeAgo'
import './index.sass'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import OrganizationIcon from 'components/OrganizationIcon'
import EndUserAvatar from 'components/EndUserAvatar'

const MEDIA_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

export default function ChatChannelListMember({
  className,
  avatar,
  preview,
  name,
  date,
  badge,
  isPersonalChatPage,
  unread,
  isSelected,
  forwarding,
  href,
  onToggleSelection,
  orgName,
  organization,
  isNotification,
  senderName,
  publicProfiles,
  myPublicProfile,
  ...props
}) {


  const { is_unread: isUnread, is_notifications_muted: isNotificationsMuted, is_pinned: isPinned, uid } = props


  function preventDefault(e) {
    e.preventDefault()
  }

  function handleChatClick(e) {
    if (forwarding) {
      e.preventDefault()
      e.stopPropagation()
      onToggleSelection(uid)
      return
    }
  }

  const addThreeDots = replaceDids(stripHTML(preview), publicProfiles, myPublicProfile).length > 27 ? '...' : ''

  let finalPreview = preview && senderName ? (
    `${senderName}: ${preview.startsWith(MEDIA_ATTACHMENT_PREFIX)
      ? previewStringForMedia(preview).substring(0, 37)
      : replaceDids(stripHTML(preview), publicProfiles, myPublicProfile).substring(0, 27) + addThreeDots}
    `
  ) : preview && (
    preview.startsWith(MEDIA_ATTACHMENT_PREFIX)
      ? previewStringForMedia(preview).substring(0, 37)
      : replaceDids(stripHTML(preview), publicProfiles, myPublicProfile).substring(0, 27) + addThreeDots
  )


  const EndUserAvatars = publicProfiles?.length > 1 && publicProfiles.map(profile => <EndUserAvatar {...{
    publicProfile: profile,
    size: 'tn',
    // style: { width: '20px', height: '20px' },
  }} />) || []

  const threeEndUserAvatars = EndUserAvatars.slice(0, 3)
  const threeEndUserAvatarsCount = EndUserAvatars.length - 3
  const threeEndUserAvatarsCountText = <span style={{
    fontWeight: '500',
    fontSize: '0.75rem',
    color: 'gray',
  }}>
    {threeEndUserAvatarsCount > 0 ? `+${threeEndUserAvatarsCount}` : ''}
  </span>

  return (
    <Link
      className={classNames('ChatChannelListMember', { className, markUnreadChat: isUnread && !unread})}
      href={href}
      onClick={handleChatClick}
    >
      {isUnread && !unread && <div className="ChatChannelListMember-markUnreadLine" />}
      {avatar}
      <div className="ChatChannelListMember-details">
        <Header size="md" className="ChatChannelListMember-name">
          {name?.length > 15 ? name?.substring(0, 15) + '...' : name}
          {EndUserAvatars.length > 0 && !isNotification && (
            <span className="ChatChannelListMember-avatars" style={{
              marginLeft: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.25rem',
            }}>
              {threeEndUserAvatars}
              {threeEndUserAvatarsCountText}
            </span>
          )}
          {isPersonalChatPage && (
            <div onClick={preventDefault} className={"ChatChannelListMember-optionsMenu"}>
              {isNotificationsMuted && <Icon
                blue type="mute" size="lg" className="ChatChannelListMember-optionsMenu-Icons-bell" />}
              {isPinned && <Icon
                blue type="pin" size="md" className="ChatChannelListMember-optionsMenu-Icons-pin" />}
            </div>
          )}
        </Header>
        {!isNotification && organization && (
          <Header size="sm" className="ChatChannelListMember-orgName">
            <OrganizationIcon {...{
              organization,
              className: 'ChatChannelListMember-orgAvatar',
              size: 'tn',
            }} />
            {orgName}
          </Header>
        )}
        {preview && (
          <div className="ChatChannelListMember-preview">
            {finalPreview}
            <div>
              {date && (
                <Fragment>
                  <TimeAgo time={date} tiny />
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {badge}
      {forwarding && (
        <div style={{padding:'0.5rem'}}>
          {!isSelected && <IconButton type="unchecked" />}
          {isSelected && <IconButton type="selected" />}
        </div>
      )}
    </Link>
  )
}

ChatChannelListMember.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.node.isRequired,
  preview: PropTypes.string,
  isSelected: PropTypes.bool,
  forwarding: PropTypes.bool,
  onToggleSelection: PropTypes.func,
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  badge: PropTypes.node,
  isPersonalChatPage: PropTypes.bool,
  synopsis: PropTypes.array,
  is_unread: PropTypes.bool,
  is_notifications_muted: PropTypes.bool,
  is_pinned: PropTypes.bool,
  uid: PropTypes.string,
  unread: PropTypes.number,
  href: PropTypes.string,
  senderName: PropTypes.string,
  orgName: PropTypes.string,
  organization: PropTypes.object,
  isNotification: PropTypes.bool,
  publicProfiles: PropTypes.array,
  myPublicProfile: PropTypes.object,
}

const previewStringForMedia = preview =>
  preview.match(/"type":"([^"]+)"/)
    ? `sent ${RegExp.$1}`
    : 'sent media attachment'

function stripHTML(html){
  const div = global.document.createElement('div')
  div.innerHTML = html
  return div.textContent.trim()
}

function replaceDids(text, publicProfiles, myPublicProfile){

  let finalPreview = text
  if(typeof text === "string"){
    if(finalPreview.includes(`@${myPublicProfile.did}`)){
      finalPreview = finalPreview.replace(`@${myPublicProfile.did}`, `@you`)
    }
    for (let i = 0; i < publicProfiles.length; i++) {
      finalPreview = finalPreview.replace(`@${publicProfiles[i].did}`, `@${publicProfiles[i].username}`)
    }
  }
  return finalPreview
}
