import {h} from "preact"
import {useEffect, useCallback} from "preact/hooks"
import {useAppState} from "lib/appState"

export function useManageConnections(componentName) {
  const {
    takeAction,
    creatingKey,
    errorcreatingConnection,
    reportKey,
    errorReport,
    fetchingConnectionsError,
    Noted,
    connections,
    people,
    suggested,
    directConnection,
  } = useAppState(
    {
      "creatingKey": "creatingConnection",
      "reportKey": "reportingConnection",
      "errorReport": "errorReportingConnection",
      "errorCreatingConnection": "errorcreatingConnection",
      "fetchingConnectionsError": "fetchingConnectionsError",
      'connections': "connections",
      'Noted': "Noted",
      'people': "people",
      'suggested': "suggested",
      "directConnection": "directConnection",
      "createNote": "createNote",

    },
    componentName
  )

  const createConnection = useCallback(async ({friend_did, status, note}) => {
    if (!friend_did || !status) {
      console.error("friend Did and status are required")
      return
    }
    await takeAction("connections.createConnection", {friend_did, status, note})
    if (status === "create" || status === "ignore") {
      await getConnectionsForUser()
    }
  }, [])
  const reportUser = useCallback(async ({ReportedUserDid, reason}) => {
    if (!ReportedUserDid || !reason) {
      console.error("ReportedUserDid and reason are required")
      return
    }
    await takeAction("connections.reportUser", {ReportedUserDid, reason})
  }, [takeAction])


  const blockUser = useCallback(async ({BlockedUserDid}) => {
    if (!BlockedUserDid) {
      console.error("BlockedUserDid  are required")
      return
    }
    await takeAction("connections.blockUser", {BlockedUserDid})
  }, [takeAction])

  const ignoreConnection = useCallback(async ({suggested_did, type}) => {
    if (!suggested_did || !type) {
      console.error("userDids and type are required")
      return
    }
    await takeAction("connections.ignoreConnection", {suggested_did, type})
    await getSuggestedConnections()
  }, [takeAction])

  const deleteConnection = useCallback(async ({friend_did}) => {
    if (!friend_did) {
      console.error("friend Did is required")
      return
    }
    await takeAction("connections.deleteConnection", {friend_did})
    await getSuggestedConnections()
  }, [])

  const getConnectionsForUser = useCallback(async () => {
    await takeAction("connections.getConnectionsUser")
  }, [takeAction])

  const getPeopleConnect = useCallback(async ({personDid}) => {
    await takeAction("connections.getPeopleConnect", {personDid})
  }, [takeAction])

  const getNote = useCallback(async ({connectedUserDid}) => {
    await takeAction("connections.getNote", {connectedUserDid})
  }, [takeAction])

  const getSuggestedConnections = useCallback(async () => {
    await takeAction("connections.getSuggestedConnections")
  }, [takeAction])

  const getDirectConnection = useCallback(async ({user_did}) => {
    await takeAction("connections.getDirectConnection", {user_did})
  }, [takeAction])

  const createNote = useCallback(async ({note, connectedUserDid}) => {
    if (!note || !connectedUserDid) {
      console.error("note and connectedUserDid are required")
      return
    }
    await takeAction("connections.createNote", {note, connectedUserDid})
  }, [takeAction])

  useEffect(() => {
    if (errorcreatingConnection) {
      console.error("Error while creating connection:", errorcreatingConnection)
    }
  }, [errorcreatingConnection])

  useEffect(() => {
    if (fetchingConnectionsError) {
      console.error(
        "Error while fetching connections:",
        fetchingConnectionsError
      )
    }
  }, [fetchingConnectionsError])

  useEffect(() => {
    async function getConnections() {
      await getConnectionsForUser()
    }
    getConnections()
  }
  , [])

  return {
    creatingKey,
    reportKey,
    errorReport,
    errorcreatingConnection,
    fetchingConnectionsError,
    connections,
    directConnection,
    suggested,
    people,
    Noted,
    getSuggestedConnections,
    createConnection,
    getConnectionsForUser,
    getDirectConnection,
    getPeopleConnect,
    deleteConnection,
    ignoreConnection,
    reportUser,
    blockUser,
    getNote,
    createNote
  }
}
