import deepcopy from 'deepcopy'
import { isLoggedIn } from '../resources/auth'
import api from '../api'
import { getNavigatorInfo, getWindowDetails } from 'lib/errorReporting'

export default function({ email, mobile, message, attachInfo }) {
  this.setState({
    sendingHelpRequest: true,
    errorSendingHelpRequest: undefined,
  })

  const helpRequest = { email, mobile, message }

  if (attachInfo){
    helpRequest.loggedIn = !!isLoggedIn()
    helpRequest.window = getWindowDetails()
    helpRequest.navigator = getNavigatorInfo()
    // helpRequest.source = window.document.documentElement.outerHTML //makes body too large
    helpRequest.appState = deepcopy(this.getState())
  }

  api.sendHelpRequest(helpRequest).then(
    () => {
      this.setState({
        sendingHelpRequest: undefined,
      })
    },
    errorSendingHelpRequest => {
      this.setState({
        sendingHelpRequest: undefined,
        errorSendingHelpRequest
      })
    }
  )
}
