import { h } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'lib/classNames'
import { useMyOrganizationMembership, useMyPublicProfile, usePublicProfile } from 'lib/membershipAppStateHooks'
import IconButton from 'components/IconButton'
import { useChatChannelsForPublicProfile, useCreateChatChannel } from 'lib/chatHooks'
import { useEffect } from 'preact/hooks'

export default function ChatButton({
  className,
  organizationApikey,
  publicProfileDid,
  ...props
}){

  useEffect(() => {
  }, [organizationApikey, publicProfileDid])
  const {
    myPublicProfileDid,
  } = useMyPublicProfile('ChatButton')
  const { myOrganizationMembership } = useMyOrganizationMembership(organizationApikey, 'ChatButton')
  const { chatChannels } = useChatChannelsForPublicProfile({
    publicProfileDid,
    organizationApikey,
  }, 'ChatButton')
  const { publicProfile } = usePublicProfile(publicProfileDid, 'ChatButton')
  const createChatChannel = useCreateChatChannel('ChatButton')

  const handleCreateChatChannel = async () => {
    if (chatChannels.length === 0) {
      await createChatChannel({
        type: 'dm',
        memberUserDids: [myPublicProfileDid, publicProfileDid],
        organizationApikey,
        name: `unnamed chat`,
        chatImage: publicProfile?.avatar || undefined,
      })
    }
  }

  if (
    !myOrganizationMembership ||
    !myOrganizationMembership.acceptedAt ||
    publicProfileDid === myPublicProfileDid
  ) return

  return (
    <IconButton
      {...props}
      className={classNames('ChatButton', { className })}
      type="messages"
      href={`/${organizationApikey}/chat/${publicProfileDid}`}
      onClick={handleCreateChatChannel}
    />
  )
}

ChatButton.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  publicProfileDid: PropTypes.string.isRequired,
}
