import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import { useAppState } from 'lib/appState'
import { useMyOrganizations } from './membershipAppStateHooks'
import useLoadedEntity from './useLoadedEntityHook'

export function useMyOrganizationsTopics(organizationApikey, componentName) {
  const {
    takeAction,
    myOrganizationsTopics,
    loadingMyOrganizationsTopics,
    loadingMyOrganizationsTopicsError
  } = useAppState({
    myOrganizationsTopics: 'myOrganizationsTopics',
    myOrganizationsTopicsLoading: 'loadingMyOrganizationsTopics',
    myOrganizationsTopicsError: 'loadingMyOrganizationsTopicsError',
  }, componentName)

  const { myOrganizations } = useMyOrganizations(componentName)

  useEffect(() => {
    if (myOrganizationsTopics) return
    // TODO - get other organization except the current one
    const organizationApikeys = myOrganizations.map(org => org.apikey).filter(apikey => apikey !== organizationApikey)
    if (organizationApikeys.length > 0) {
      takeAction('topics.loadMyOrganizationsTopics', organizationApikeys)
        .catch(error => console.error('Error fetching topics:', error))
    }
  }, [myOrganizations])

  return {
    myOrganizationsTopics,
    loadingMyOrganizationsTopics,
    loadingMyOrganizationsTopicsError
  }
}


export function useOrganizationTopics(organizationApikey, componentName){

  const {
    takeAction,
    loadingOrganizationTopics,
    organizationTopics,
    loadingOrganizationTopicsError,
    activeTopic,
  } = useAppState({
    [`organizationTopics:${organizationApikey}`] : 'organizationTopics',
    [`organizationTopics:${organizationApikey}:loading`] : 'loadingOrganizationTopics',
    [`organizationTopics:${organizationApikey}:error`] : 'loadingOrganizationTopicsError',
    [`organization:${organizationApikey}:activeTopic`]: 'activeTopic',
  },
  componentName
  )

  useEffect(() => {
    if (organizationTopics) return
    takeAction('topics.loadOrganizationTopics', organizationApikey)
  }, [organizationTopics, organizationApikey])

  useEffect(() => {
    if (organizationTopics && activeTopic) return
    takeAction('topics.loadDefaultTopic', organizationApikey)
  }
  , [organizationTopics, activeTopic])

  return {
    loadingOrganizationTopics,
    organizationTopics,
    activeTopic,
    loadingOrganizationTopicsError,
  }
}

export function useCreateTopic(organizationApikey, topic, componentName){
  const {
    takeAction,
    creatingTopic,
    createTopicError,
    createdTopic
  } = useAppState({
    [`organization:${organizationApikey}:creatingTopic`] : 'creatingTopic',
    [`organization:${organizationApikey}:createTopicError`] : 'createTopicError',
    [`organization:${organizationApikey}:createdTopic`] : 'createdTopic'
  },
  componentName
  )

  const createTopic = useCallback(() => {
    takeAction('topics.createTopic', organizationApikey, topic)
  }
  , [topic])

  return {
    createTopic,
    creatingTopic,
    createTopicError,
    createdTopic
  }

}

export function useCreateSubtopic({ organizationApikey, topicUid, topic, subtopic, componentName }){
  const {
    takeAction,
    creatingSubtopic,
    createSubtopicError,
    createdSubtopic
  } = useAppState({
    [`organization:${organizationApikey}:creatingSubtopic`] : 'creatingSubtopic',
    [`organization:${organizationApikey}:createSubtopicError`] : 'createSubtopicError',
    [`organization:${organizationApikey}:createdSubtopic`] : 'createdSubtopic'
  },
  componentName
  )


  const createTopicAndSubtopic = useCallback(() => {
    takeAction('topics.createTopicAndSubtopic', organizationApikey, topic, subtopic)
  }
  , [subtopic, topic, organizationApikey])


  const createSubtopic = useCallback(() => {
    takeAction('topics.createSubtopic', { organizationApikey, topicUid, subtopic })
  }
  , [subtopic, topicUid, organizationApikey])

  return {
    createSubtopic,
    creatingSubtopic,
    createSubtopicError,
    createdSubtopic,
    createTopicAndSubtopic
  }

}
export function useEditTopic(organizationApikey, topicUid, changes, componentName){
  const {
    takeAction,
    editingTopic,
    editTopicError,
    editedTopic
  } = useAppState({
    [`organization:${organizationApikey
    }:editingTopic:${topicUid}`] : 'editingTopic',
    [`organization:${organizationApikey
    }:editTopicError:${topicUid}`] : 'editTopicError',
    [`organization:${organizationApikey
    }:editedTopic:${topicUid}`] : 'editedTopic'
  },
  componentName
  )

  const editTopic = useCallback(() => {
    takeAction('topics.editTopic', organizationApikey, topicUid, changes)
  }, [topicUid, changes])

  return {
    editTopic,
    editingTopic,
    editTopicError,
    editedTopic
  }

}

export function useTransferTopicFeedPosts(organizationApikey, componentName){
  const {
    takeAction,
    transferringTopicsFeedPosts,
    transferTopicFeedPostsError,
    transferredSubtopics
  } = useAppState({
    [`organization:${organizationApikey}:transferringTopicsFeedPosts`] : 'transferringTopicsFeedPosts',
    [`organization:${organizationApikey}:transferTopicFeedPostsError`] : 'transferTopicFeedPostsError',
    [`organization:${organizationApikey}:transferredSubtopics`] : 'transferredSubtopics'
  },
  componentName
  )

  const transferTopicFeedPosts = useCallback((topicUid, newTopicUid) => {
    takeAction('topics.transferTopicFeedPosts', organizationApikey, topicUid,  newTopicUid)
  }
  , [organizationApikey])

  return {
    transferTopicFeedPosts,
    transferringTopicsFeedPosts,
    transferTopicFeedPostsError,
    transferredSubtopics
  }
}

export function useDeleteTopic(organizationApikey, topicUid, componentName){
  const {
    takeAction,
    deletingTopic,
    deletingTopicError,
  } = useAppState({
    [`organization:${organizationApikey}:deletingTopic:${topicUid}`] : 'deletingTopic',
    [`organization:${organizationApikey}:deletingTopicError:${topicUid}`] : 'deletingTopicError',
  },
  componentName
  )

  const deleteTopic = useCallback(() => {
    takeAction('topics.deleteTopic', organizationApikey, topicUid)
  }, [topicUid])

  return {
    deleteTopic,
    deletingTopic,
    deletingTopicError,
  }
}

export function useDefaultTopic(organizationApikey, componentName){
  const {
    takeAction,
    organizationTopics,
    defaultTopic
  } = useAppState({
    [`organizationTopics:${organizationApikey}`] : 'organizationTopics',
    [`organization:${organizationApikey}:activeTopic`]: 'defaultTopic'
  },
  componentName
  )

  useEffect(() => {
    if (organizationTopics && defaultTopic) return
    takeAction('topics.loadDefaultTopic', organizationApikey)
  }, [organizationTopics, defaultTopic])

  return {
    organizationTopics,
    defaultTopic
  }
}

export function useTopicForm(initialState) {
  const [topic, setTopic] = useState(initialState)

  const onChange = (changes) => {
    setTopic(prev => ({ ...prev, ...changes }))
  }

  return [topic, setTopic, onChange]
}

export async function useTopicsOrdering(organizationApikey, componentName) {
  const {
    takeAction,
    updatingTopicsOrder,
    updateTopicsOrderError,
    updatedTopicsOrder
  } = useAppState({
    [`organization:${organizationApikey}:updatingTopicsOrder`] : 'updatingTopicsOrder',
    [`organization:${organizationApikey}:updateTopicsOrderError`] : 'updateTopicsOrderError',
    [`organization:${organizationApikey}:updatedTopicsOrder`] : 'updatedTopicsOrder'
  },
  componentName
  )

  const updateTopicsOrder = useCallback((changes) => {
    takeAction('topics.updateTopicsOrder', organizationApikey, changes)
  }, [organizationApikey])

  return {
    updateTopicsOrder,
    updatingTopicsOrder,
    updateTopicsOrderError,
    updatedTopicsOrder
  }
}

export function useTopicFollowers({ organizationApikey, topicUid }, componentName) {
  return useLoadedEntity({
    entityKey: 'verifyUserTopicFollower',
    loadEntity: takeAction => takeAction('topics.verifyUserTopicFollower', organizationApikey, topicUid),
    componentName
  })
}

export function useAllTopicsFollowers({ organizationApikey }, componentName) {
  return useLoadedEntity({
    entityKey: 'verifyUserFollowsAllOrganizationTopics',
    loadEntity: takeAction => takeAction('topics.verifyUserFollowsAllOrganizationTopics', organizationApikey),
    componentName
  })
}
