export function publicProfileToDisplayName({ displayName = '', username }){
  return `${displayName || username}`.trim()
}

export function publicProfileToPathname(profile){
  const username = profile?.username
  const asMemberOf = profile?.asMemberOf
  if (username) return asMemberOf
    ? `/${asMemberOf}/members/@${username}`
    : `/@${username}`
}
