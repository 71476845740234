import { h, Fragment } from 'preact'
import Modal from 'components/Modal'
import { useState, useRef, useEffect } from 'preact/hooks'
import Header from 'components/Header'
import Form from 'components/Form'
import Button from 'components/Button'
import { useRequestOrganizationDeletion } from 'lib/organizationAdminHooks'
import { createPageAlert } from 'resources/pageAlerts'
import { useMyExternalIdentities } from 'lib/externalIdentitiesHooks'
import { isRecentLoggedIn } from 'resources/auth'
import Alert from 'components/Alert'
import APortalLoginForm from 'components/APortalLoginForm'
import './index.sass'

export default function DeleteHubPage({organization}) {
  const organizationApikey = organization.apikey
  const [showModal, setShowModal] = useState(true)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [error, setError] = useState('')
  const mainModalRef = useRef(null)
  const confirmationModalRef = useRef(null)

  const { externalIdentities } = useMyExternalIdentities('DeleteHubPage')
  const { requestDeletion } = useRequestOrganizationDeletion(organizationApikey, 'DeleteHubPage')

  const hasVerifiedEmail = Object.entries(externalIdentities || {}).some(
    // eslint-disable-next-line no-unused-vars
    ([_, identity]) => identity.type === 'email' && identity.verified
  )

  const verifiedContacts = Object.entries(externalIdentities || {})
    // eslint-disable-next-line no-unused-vars
    .filter(([_, identity]) => identity.verified)
    .reduce((acc, [contact, identity]) => {
      acc[identity.type] = contact
      return acc
    }, {})

  function displayModal() {
    setShowModal(!showModal)
    window.history.back()
  }

  function handleContinue() {
    console.log('isRecentLoggedIn()', isRecentLoggedIn())
    if (isRecentLoggedIn()) {
      setShowConfirmation(true)
    } else {
      setShowLoginForm(true)
    }
  }


  useEffect(() => {
    function handleOutsideClick(event) {
      if (showModal && mainModalRef.current && !mainModalRef.current.contains(event.target)) {
        setShowModal(false)
      }
      if (showConfirmation && confirmationModalRef.current && !confirmationModalRef.current.contains(event.target)) {
        setShowConfirmation(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [showModal, showConfirmation])

  async function handleDeletion() {
    setError('')
    try {
      await requestDeletion(organizationApikey)
      setShowConfirmation(false)
      createPageAlert({
        message: "Deletion request sent to support successfully! We will get back to you as soon as possible",
        type: "success"
      })
    } catch (err) {
      createPageAlert({
        message: "Error sending the deletion request.",
        type: "error"
      })
    }
    setShowModal(false)
  }

  return (
    <Fragment>
      {showModal && (
        <div ref={mainModalRef}>
          <Modal style={{ width: '60%' }}>
            {!showLoginForm && !showConfirmation ? (
              <>
                <Header size="lg">Permanently delete hub?</Header>
                <div className="delete-hub-content">
                  <p>When you delete your hub:</p>
                  <ul className="deletion-info-list">
                    <li>Your request will be sent to TRU administration for review</li>
                    <li>The process may take up to 30 days to complete</li>
                    <li>All your messages and shared content will be permanently deleted</li>
                    <li>This action cannot be undone</li>
                  </ul>

                  {!hasVerifiedEmail && (
                    <div className="warning-message">
                      <Alert type="warning">
                        Please add a verified email address before proceeding.
                      </Alert>
                      This is required so our administration team can contact you about your deletion request.
                    </div>
                  )}

                  {hasVerifiedEmail && (
                    <div className="contact-info">
                      <p className="contact-header">Our team will contact you using:</p>
                      {verifiedContacts.email && (
                        <p className="contact-detail">Email: {verifiedContacts.email}</p>
                      )}
                      {verifiedContacts.mobile && (
                        <p className="contact-detail">Mobile: {verifiedContacts.mobile}</p>
                      )}
                    </div>
                  )}
                </div>
                <hr />
                <div className="small_btn">
                  <Button className="small_cancel nextBtn" onClick={displayModal}>Cancel</Button>
                  {hasVerifiedEmail && (
                    <Button
                      className={`small_continue nextBtn ${!hasVerifiedEmail ? 'disabled' : ''}`}
                      onClick={handleContinue}
                      disabled={!hasVerifiedEmail}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </>
            ) : !isRecentLoggedIn() ? (
              <div style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Header size="lg">Confirm Your Identity</Header>
                <APortalLoginForm requireRecentLogin />
                <div className="small_btn" style={{marginTop: '20px'}}>
                  <Button className="small_cancel nextBtn" onClick={() => setShowLoginForm(false)}>Back</Button>
                </div>
              </div>
            ) : (
              <div style={{padding: '20px', display: 'flex', flexDirection: 'column'}}>
                <Header size="lg">Confirm Deletion</Header>
                <p>Are you sure you want to proceed with deleting your hub? This action cannot be undone.</p>
                {error && <p className="error">{error}</p>}
                <Form.ButtonRow>
                  <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
                  <Button type="primary" onClick={handleDeletion}>Confirm Deletion</Button>
                </Form.ButtonRow>
              </div>
            )}
          </Modal>
        </div>
      )}
    </Fragment>
  )
}
