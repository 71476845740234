import { h } from 'preact'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'preact/hooks'

import { useFeed } from 'lib/feedPostHooks'

import InfiniteScrollDown from 'components/InfiniteScrollDown'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import LoggedOutFeedPostsMessage from 'components/LoggedOutFeedPostsMessage'
import api from '../../a-portal/api/index'
import { useAppState } from 'lib/appState'
import './index.sass'

export default function OrganizationFeedPosts({
  className = '',
  feed,
  emptyMessage,
  fullyLoadedMessage,
  hideConsumedPosts,
  loggedOut,
  organizationApikey,
  dedupe = false,
  feedPage,
  topicUid
}){

  const {refreshPinned} = useAppState({
    [`organization:refreshPinned`]: 'refreshPinned',
  }, 'OrganizationPage')


  const [pinnedPostIds, setPinnedPostIds] = useState(new Set())
  useEffect(() => {
    api.getPinnedPostsForOrganization({ organizationApikey })
      .then(response => {
        const pinnedIds = new Set(
          response.pinnedPosts.pinnedPosts.pinnedPosts.map(post => post.uid)
        )
        setPinnedPostIds(pinnedIds)
      })
  }, [organizationApikey, refreshPinned])

  let {
    loading,
    loadingError,
    posts,
    fullyLoaded,
    loadMore,
  } = useFeed({feed, pageSize: 100}, 'OrganizationFeedPosts')

  if (topicUid) {
    posts = posts.filter(post => post.topicUid === topicUid)
  }
  posts = posts.map(post => ({
    ...post,
    pinned: pinnedPostIds.has(post.uid)
  }))

  const seenPosts = new Set()
  posts = posts.filter(post => {

    if (topicUid && pinnedPostIds.has(post.uid)) return false

    if (dedupe) {
      if (seenPosts.has(post.feedPostContentUid)) return false
      seenPosts.add(post.feedPostContentUid)
    }
    return !hideConsumedPosts || !post.parentUid
  })

  if (loggedOut && fullyLoaded && posts.length === 0)
    return <LoggedOutFeedPostsMessage destinationOrganizationApikey={organizationApikey} />

  return <InfiniteScrollDown {...{
    className: `OrganizationFeedPosts ${className}`,
    name: 'posts',
    loading,
    loadingError,
    fullyLoaded,
    loadMore,
    emptyMessage,
    fullyLoadedMessage,
  }}>
    {posts.map(post =>
      <OrganizationFeedPost
        key={post.uid}
        sortBy={post.lastPublishedAt || post.createdAt}
        {...{
          post,
          topicUid: post.topicUid,
          isFromFollowedTopic: post.visibleTo === 0,
          organizationApikey: post.feedOrganizationApikey,
          feedPage,
        }}
        withinFeed
        feed={feed}
      />
    )
    }
  </InfiniteScrollDown>
}

OrganizationFeedPosts.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string,
  feed: PropTypes.string.isRequired,
  emptyMessage: InfiniteScrollDown.propTypes.emptyMessage,
  fullyLoadedMessage: InfiniteScrollDown.propTypes.fullyLoadedMessage,
  usersFeed: PropTypes.bool,
  hideConsumedPosts: PropTypes.bool,
  loggedOut: PropTypes.bool,
  dedupe: PropTypes.bool,
  topicUid: PropTypes.string,
  feedPage: PropTypes.bool,
}
