import { Fragment, h } from "preact"
import { useEffect, useMemo, useState, useCallback } from "preact/hooks"
import { setPageTitle } from "lib/Page"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import ContentBox from "components/ContentBox"
import Icon from "components/Icon"
import Header from "components/Header"
import IconButton from "components/IconButton"
import { ChatsList } from "../OrganizationChatPage/ChatsList"
import { CurrentChat } from "../OrganizationChatPage/CurrentChat"
import {
  useMyOrganizations,
  useMyPublicProfile,
} from "lib/membershipAppStateHooks"
import {
  countUnreadMessages,
  useChatChannelsForPerson,
  useChatSynopsis,
  useFasterChatPolling,
  useOrganizationChatSynopsis,
} from "lib/chatHooks"
import { findDMChatChannel } from "../OrganizationChatPage/findDMChatChannel"
import { replaceLocation } from "lib/locationResource"
import ButtonWithDropdown from "components/ButtonWithDropdown"
import NotFoundPage from "../NotFoundPage"
import SearchInput from "components/SearchInput"
import StarredMessagesModal from "components/StarredMessagesModal"
import NewChatModal from "components/NewChatModal"
import ThreadRepliesModal from "components/ThreadRepliesModal"
import EditChatInfoModal from "components/EditChatInfoModal"
import ManageChatMembersModal from "components/ManageChatMembersModal"
import AssignAdminModal from "components/AssignAdminModal"
import ChatInfoPanel from "components/ChatInfoPanel"
import AddChatMembersModal from "components/AddChatMembersModal"
import ChatSearchModal from "components/ChatSearchModal"
import "./index.sass"
import ChatChip from "components/HubChiplist"
import Loading from "components/Loading"

import PNFO from "jlinc-shared/PNFO"
import InterfaceHelp from "components/InterfaceHelp"
import "./index.sass"

export function ChatContentBox({ organization:paramOrganization, pageType = "organization" }) {
  const chatHref = (channel) =>
    `${pageType === "organization" ? `/${organization.apikey}` : ""}/chat` +
    (channel ? `/${channel}` : "")

  const [organization, setOrganization] = useState(paramOrganization ? paramOrganization : {})

  const { chatSynopsis } = useChatSynopsis("ChatPage")
  const { organizationChatSynopsis } = useOrganizationChatSynopsis(
    organization.apikey,
    "OrganizationChatPage"
  )

  const synopsis = pageType === "organization" ? organizationChatSynopsis : chatSynopsis

  useFasterChatPolling("ChatPage")
  const { chatChannels } = useChatChannelsForPerson("ChatPage")
  const organizations = useMemo(
    () => chatChannels && chatChannels?.chat_channels,
    [chatChannels]
  )
  const chatPage = window.location.pathname.split("/chat/")[1]
  let channelParam = window.location.pathname.split("/chat/")[1]

  const validChannelParam =
    synopsis && synopsis.find((o) => o.uid === channelParam)

  let currentChat =
    channelParam &&
    (channelParam.match(/^[0-9a-f]{32}$/i)
      ? { chatChannelUid: channelParam }
      : channelParam.startsWith("did:")
        ? { dm: channelParam }
        : undefined)

  const { myOrganizations } = useMyOrganizations("ChatContentBox")
  const { myPublicProfile } = useMyPublicProfile("ChatContentBox")

  const [forwarding, setForwarding] = useState(false)
  const [leftPage, setLeftPage] = useState("chats")
  const isStarredMessagesModalOpen = leftPage === "starredMessages"
  const openStarredMessagesModal = () => {
    setLeftPage("starredMessages")
  }
  const closeStarredMessagesModal = () => {
    setLeftPage("chats")
  }
  const isGroupEditModalOpen = leftPage === "groupEdit"
  const handleOpenGroupEditModal = () => {
    setLeftPage("groupEdit")
  }
  const handleCloseEditModal = () => {
    setLeftPage("chats")
  }

  const showManageChatMembersModal = leftPage === "manageChatMembers"
  const handleManageMembers = () => {
    setLeftPage("manageChatMembers")
  }
  const setShowManageChatMembersModal = (value) => {
    setLeftPage(value ? "manageChatMembers" : "chats")
  }
  const handleCloseManageChatMembersModal = () => {
    setLeftPage("chats")
  }
  const  showAddChatMembersModal = leftPage === "addChatMembers"
  const handleOpenAddChatMembersModal = () => {
    setLeftPage("addChatMembers")
  }
  const handleCloseAddChatMembersModal = () => {
    setLeftPage("manageChatMembers")
  }

  const showAdminTransferModal = leftPage === "assignAdmin"
  const handleOpenAssignAdminModal = () => {
    setLeftPage("assignAdmin")
  }
  const handleCloseAssignAdminModal = () => {
    setLeftPage("chats")
  }
  const isChatInfoPanelOpen = leftPage === "chatInfoPanel"
  const openChatInfoPanel = () => {
    setLeftPage("chatInfoPanel")
  }
  const closeChatInfoPanel = () => {
    setLeftPage("chats")
  }
  const isChatSearchModalOpen = leftPage === "chatSearch"
  const openChatSearchModal = () => {
    setLeftPage("chatSearch")
  }
  const closeChatSearchModal = () => {
    setLeftPage("chats")
  }

  const isNewChatModalOpen = leftPage === "newChat"

  const openNewChatModal = () => {
    setLeftPage("newChat")
  }
  const closeNewChatModal = () => {
    setLeftPage("chats")
  }

  const isThreadRepliesModalOpen = leftPage === "replies"
  const openThreadRepliesModal = () => {
    setLeftPage("replies")
  }
  const closeThreadRepliesModal = () => {
    setLeftPage("chats")
    setForwarding(false)
  }

  const [chatChip, setChatChip] = useState([])
  const [selectedChats, setSelectedChats] = useState(new Set())
  const [openforwardWindow, setOpenforwardWindow] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [leftPanelWidth, setLeftPanelWidth] = useState(30)
  const MIN_WIDTH_PERCENT = 30
  const MAX_WIDTH_PERCENT = 50

  useFasterChatPolling("OrganizationChatPage")

  const channelUidsToForwardTo = Array.from(selectedChats)
  const handleForwardClick = async () => {
    if (chatChip.length < 1) {
      return
    }
    setOpenforwardWindow(true)
    setForwarding(true)
    setLeftPage("forward")
  }
  const handlecancelForward = async () => {
    setForwarding(false)
    setChatChip([])
    setSelectedChats(new Set())
  }

  useEffect(() => {
    if (forwarding) {
      setLeftPage("forward")
    } else {
      setLeftPage("chats")
    }
  }, [forwarding])

  const toggleChatSelection = (uid) => {
    //if (!forwarding) return

    const updatedSelectedChats = new Set(selectedChats)
    if (updatedSelectedChats.has(uid)) {
      updatedSelectedChats.delete(uid)
    } else {
      updatedSelectedChats.add(uid)
    }
    setSelectedChats(updatedSelectedChats)
  }

  useEffect(() => {
    if (!channelParam || !organizations || !myOrganizations) return
    const selectedOrganization = organizations.find(
      (o) => o.channel_uid === channelParam
    )
    const organization = myOrganizations.find(
      (o) => o?.apikey === selectedOrganization?.apikey
    )
    if (selectedOrganization && organization) setOrganization(organization)
  }, [channelParam, organizations, myOrganizations])

  const matchingChatChannel =
    synopsis &&
    currentChat &&
    currentChat.dm &&
    currentChat.dm !== myPublicProfile.did &&
    findDMChatChannel(synopsis, currentChat.dm)

  if (matchingChatChannel)
    currentChat = { chatChannelUid: matchingChatChannel.uid }

  const loading = !synopsis || matchingChatChannel

  useEffect(() => {
    if (currentChat && currentChat.dm === myPublicProfile.did)
      replaceLocation(chatHref())
    else if (matchingChatChannel)
      replaceLocation(chatHref(matchingChatChannel.uid))
  }, [channelParam])

  useEffect(() => {
    if (openforwardWindow && selectedChats.size === 0) {
      handlecancelForward()
      setOpenforwardWindow(false)
    }
  }, [openforwardWindow, selectedChats])
  const handleMouseMove = (e) => {
    if (!isDragging) return
    const windowWidth = window.innerWidth + 40
    const newWidth = ((e.clientX - 30) / windowWidth) * 100
    if (newWidth >= MIN_WIDTH_PERCENT && newWidth <= MAX_WIDTH_PERCENT) {
      setLeftPanelWidth(newWidth)
    }
  }
  const handleMouseDown = () => {
    setIsDragging(true)
    document.body.classList.add("no-select")
  }
  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false)
      document.body.classList.remove("no-select")
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [isDragging, handleMouseMove])
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }
    return () => {
      // Cleanup event listeners to avoid memory leaks
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  const totalUnread = useMemo(
    () => (synopsis ? countUnreadMessages(synopsis) : 0),
    [synopsis]
  )
  setPageTitle(
    `${totalUnread > 0 ? `❗${totalUnread} ` : ""}DMs - ${APP_NAME}`
  )


  const isMainChatPage = chatPage === undefined || chatPage === ""

  const [searchParam, setSearchParam] = useState("")
  const [replyingTo, setReplyingTo] = useState(null)
  const [isEditing, setIsEditing] = useState(null)
  const [isEditingThread, setIsEditingThread] = useState(null)
  const [editedMessage, setEditedMessage] = useState("")
  const [editMessageUid, setEditMessageUid] = useState(null)
  const [sortOption, setSortOption] = useState("NewestToOldest")
  const [filterOption, setFilterOption] = useState("all")
  if (loading) return <Loading />
  if (!isMainChatPage && !validChannelParam) return <NotFoundPage />

  const isThread = false

  const chatChannelUid = channelParam

  const preventLinkInteraction = (e) => {
    if (isDragging) {
      e.preventDefault()
    }
  }

  return (
    <Fragment>
      <ContentBox
        className="OrganizationChatPage-ChatContentBox"
        x-open={!!currentChat}
      >
        {isDragging && <div className="drag-overlay" />}
        <div
          className={`OrganizationChatPage-left ${isThreadRepliesModalOpen ? "threadRepliesModalOpen" : ""}`}
          style={{ "--width": `${leftPanelWidth}%` }}
        >
          {isThreadRepliesModalOpen && (
            <div className="OrganizationChatPage-ThreadRepliesModal">
              <ThreadRepliesModal
                {...{
                  open: openThreadRepliesModal,
                  close: closeThreadRepliesModal,
                  chatChannelUid: channelParam,
                  synopsis,
                  organization,
                  replyingTo,
                  setReplyingTo,
                  isEditing,
                  setIsEditing,
                  isEditingThread,
                  setIsEditingThread,
                  editedMessage,
                  setEditedMessage,
                  editMessageUid,
                  setEditMessageUid,
                }}
              />
            </div>
          )}
          {isNewChatModalOpen && (
            <NewChatModal
              {...{
                open: isNewChatModalOpen,
                onClose: closeNewChatModal,
                organizationApikey:
                  pageType === "organization"
                    ? organization.apikey
                    : undefined,
                organizations,
              }}
            />
          )}
          {isStarredMessagesModalOpen && (
            <div className="OrganizationChatPage-StarredMessagesModal">
              <StarredMessagesModal
                {...{
                  open: isStarredMessagesModalOpen,
                  onClose: closeStarredMessagesModal,
                  chatChannel: channelParam,
                  closeStarredMessagesModal,
                  openThreadRepliesModal,
                }}
              />
            </div>
          )}
          {isGroupEditModalOpen && (
            <div className="OrganizationChatPage-EditChatInfoModal">
              <EditChatInfoModal
                {...{
                  open: handleOpenGroupEditModal,
                  onClose: handleCloseEditModal,
                  chatChannelUid: channelParam,
                  isGroupEditModalOpen,
                  handleOpenGroupEditModal,
                }}
              />
            </div>
          )}
          {showManageChatMembersModal && (
            <div className="OrganizationChatPage-EditChatInfoModal">
              <ManageChatMembersModal
                {...{
                  open: showManageChatMembersModal,
                  onClose: handleCloseManageChatMembersModal,
                  chatChannelUid: chatChannelUid,
                  chatChannel: channelParam,
                  handleManageMembers,
                  setShowManageChatMembersModal,
                  showManageChatMembersModal,
                  handleOpenAddChatMembersModal,
                }}
              />
            </div>
          )}
          {showAddChatMembersModal && (
            <div className="OrganizationChatPage-EditChatInfoModal">
              <AddChatMembersModal
                open={showAddChatMembersModal}
                onClose={handleCloseAddChatMembersModal}
                chatChannelUid={channelParam}
              />
            </div>
          )}
          {showAdminTransferModal && (
            <div className="OrganizationChatPage-EditChatInfoModal">
              <AssignAdminModal
                {...{
                  open: handleOpenAssignAdminModal,
                  chatChannel: channelParam,
                  onClose: handleCloseAssignAdminModal,
                  chatChannelUid: chatChannelUid,
                  myPublicProfile: myPublicProfile,
                }}
              />
            </div>
          )}
          {isChatInfoPanelOpen && (
            <div className="OrganizationChatPage-EditChatInfoModal">
              <ChatInfoPanel
                {...{
                  open: isChatInfoPanelOpen,
                  onClose: closeChatInfoPanel,
                  chatChannel: channelParam,
                  handleOpenGroupEditModal,
                  isGroupEditModalOpen,
                  handleOpenAddChatMembersModal,
                }}
              />
            </div>
          )}
          {isChatSearchModalOpen && (
            <div className="OrganizationChatPage-StarredMessagesModal">
              <ChatSearchModal
                {...{
                  open: isChatSearchModalOpen,
                  onClose: closeChatSearchModal,
                  chatChannel: channelParam,
                  closeChatSearchModal,
                }}
              />
            </div>
          )}
          {(leftPage === "chats" || leftPage === "forward") && (
            <>
              {forwarding && (
                <div
                  className="OrganizationChatPage-header-main"
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid rgb(209, 211, 221)",
                  }}
                >
                  <IconButton
                    type="arrowback"
                    onClick={() => setForwarding(false)}
                  />
                  <span style={{ marginLeft: "0.5rem" }}>
                    Select Chat to Forward Message
                  </span>
                </div>
              )}
              {pageType !== "organization" && (
                <div className="OrganizationChatPage-header-main">
                  <SearchInput
                    {...{
                      placeholder: "Search…",
                      className: "OrganizationChatPage-SearchBar",
                      interfaceHelp: {
                        position: "tl",
                        inside: true,
                        title: "Search",
                        content: `Search for chats`,
                      },
                      value: searchParam,
                      onChange: setSearchParam,
                    }}
                  />
                  <SortBox {...{ setSortOption }} />
                  <FilterBox {...{ setFilterOption }} />
                </div>
              )}
              {forwarding && chatChip.length > 0 && (
                <div style={{ padding: "0.5rem" }} className="chatchip">
                  <ChatChip chats={chatChip} onDelete={toggleChatSelection} />
                </div>
              )}
              <div className="OrganizationChatPage-container">
                {pageType === "organization" ? (
                  <>
                    <div className="OrganizationChatPage-header">
                      <Icon type="messages" size="lg" />
                      <Header size="lg">Chat</Header>
                      <InterfaceHelp
                        {...{
                          title: "Direct Message Group Chat",
                          content: `Create a direct message group with any other members of this ${PNFO.singular}`,
                        }}
                      >
                        <IconButton
                          className="OrganizationChatPage-openNewChat"
                          onClick={openNewChatModal}
                          type="create"
                        />
                      </InterfaceHelp>
                    </div>
                    <div className="OrganizationChatPage-Chats">
                      <ChatsList
                        {...{
                          chatHref,
                          myPublicProfile,
                          organization,
                          synopsis,
                          isPersonalChatPage: true,
                          currentChat,
                          forwarding,
                          toggleChatSelection,
                          selectedChats,
                          setChatChip,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="OrganizationChatPage-dm">
                      <div className="OrganizationChatPage-header">
                        <Icon type="messages" size="lg" />
                        <Header size="lg">Direct Chats</Header>
                      </div>
                      <div className="OrganizationChatPage-Chats">
                        <ChatsList
                          onClick={preventLinkInteraction}
                          {...{
                            chatHref,
                            myPublicProfile,
                            synopsis,
                            currentChat,
                            organization,
                            typeFilter: "dm",
                            isPersonalChatPage: true,
                            searchParam,
                            forwarding,
                            toggleChatSelection,
                            selectedChats,
                            setChatChip,
                            sortOption,
                            filterOption,
                          }}
                        />
                      </div>
                    </div>
                    <div className="OrganizationChatPage-hubs">
                      <div className="OrganizationChatPage-header">
                        <Icon type="messages" size="lg" />
                        <Header size="lg">Hub Chats</Header>
                      </div>
                      <div className="OrganizationChatPage-Chats">
                        <ChatsList
                          onClick={preventLinkInteraction}
                          {...{
                            chatHref,
                            myPublicProfile,
                            synopsis,
                            currentChat,
                            organization,
                            typeFilter: "general",
                            isPersonalChatPage: true,
                            searchParam,
                            forwarding,
                            toggleChatSelection,
                            selectedChats,
                            setChatChip,
                            sortOption,
                          }}
                        />
                      </div>
                    </div>
                    {leftPage === "chats" && !forwarding && (
                      <div className="newchatFixed" onClick={openNewChatModal}>
                        <Icon type="newChat" size="lg" />
                      </div>
                    )}
                  </>
                )}
              </div>
              {forwarding &&  <ButtonRow>
                <Button
                  type="normal"
                  value="cancel"
                  onClick={handlecancelForward}
                />
                <Button
                  onClick={handleForwardClick}
                  type="primary"
                  value="create"
                />
              </ButtonRow>}
            </>
          )}
        </div>
        <div className="divider" onMouseDown={handleMouseDown} />
        <div className="OrganizationChatPage-right" style={{ flexGrow: 1 }}>
          <CurrentChat
            {...{
              key: `${channelParam}`,
              loading,
              organization,
              myPublicProfile,
              synopsis,
              currentChat,
              openNewChatModal,
              openThreadRepliesModal,
              openStarredMessagesModal,
              closeThreadRepliesModal,
              openChatSearchModal,
              openChatInfoPanel,
              closeChatSearchModal,
              setForwarding,
              isGroupEditModalOpen,
              handleOpenGroupEditModal,
              setShowManageChatMembersModal,
              showManageChatMembersModal,
              handleOpenAssignAdminModal,
              isChatInfoPanelOpen,
              closeStarredMessagesModal,
              isPersonalChatPage: pageType !== "organization",
              href: channelParam,
              replyingTo,
              setReplyingTo,
              isEditing,
              isThread,
              channelUidsToForwardTo,
              setIsEditing,
              editedMessage,
              setEditedMessage,
              editMessageUid,
              setEditMessageUid,
              isEditingThread,
              setIsEditingThread,
              searchParam,
              setSearchParam,
              isMainChatPage,
              openforwardWindow,
              setOpenforwardWindow,
            }}
          />
        </div>
      </ContentBox>
    </Fragment>
  )
}

function SortBox({
  setSortOption,
}) {
  const sortOptions = [
    {
      title: "Name A - Z",
      onClick: () => setSortOption("AToZ")
    },
    {
      title: "Name Z - A",
      onClick: () => setSortOption("ZToA")
    },
    {
      title: "Activity Oldest to Newest",
      onClick: () => setSortOption("OldestToNewest")
    },
    {
      title: "Activity Newest to Oldest",
      onClick: () => setSortOption("NewestToOldest")
    },
  ]
  return (
    <div className="OrganizationChatPage-sortBox">
      <ButtonWithDropdown
        dropdownOptions={sortOptions}
        buttonValue="Sort"
        type="normal"
      />
    </div>
  )
}
function FilterBox({
  setFilterOption,
}) {
  const filterOptions = [
    {
      title: "All",
      onClick: () => setFilterOption("all")
    },
    {
      title: "Direct Messages",
      onClick: () => setFilterOption("dm")
    },
    {
      title: "Hub Chats",
      onClick: () => setFilterOption("general")
    },
  ]
  return (
    <div className="OrganizationChatPage-filterBox">
      <ButtonWithDropdown
        dropdownOptions={filterOptions}
        buttonValue="Filter"
        type="normal"
      />
    </div>
  )
}
