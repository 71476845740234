import { h } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'lib/classNames'
import StyleishModal from 'components/StyleishModal'

import OrganizationForumFeedPostDeleteRequestModal from 'components/OrganizationForumFeedPostDeleteRequestModal'
import './index.sass'

export default function FeedPostDeleteRequestModal({
  className,
  post,
  ...props
}){
  return <StyleishModal scrollInternally {...{
    title: 'Request Delete Feed Post',
    ...props,
    className: classNames('FeedPostDeleteRequestModal', {className}),
  }}>
    <OrganizationForumFeedPostDeleteRequestModal {...{post}} />
  </StyleishModal>
}

FeedPostDeleteRequestModal.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
}
