import { h } from 'preact'
import { useOrganization, usePublicProfile, usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useChatChannel } from 'lib/chatHooks'
import { ChatListMember } from './ChatListMember'
import './index.sass'
import EndUserDMAvatar from 'components/EndUserDMAvatar'
import EndUserAvatar from 'components/EndUserAvatar'
import { useMyPublicProfileDid } from 'resources/auth'


export function DMChatListMember({
  isSelected, forwarding, onToggleSelection, memberUserDids,
  myPublicProfile, organizationApikey, senderUserDid, ...props
}) {
  const otherUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(otherUserDids, 'OrganizationChatPage')


  const {
    chatChannel,
  } = useChatChannel(props.uid, 'OrganizationChatPage')

  const myPublicProfileDid = useMyPublicProfileDid()
  const { publicProfile: senderProfile } = usePublicProfile(senderUserDid, 'OrganizationChatPage')

  const {
    organization
  } = useOrganization(organizationApikey, 'OrganizationChatPage')

  const orgName = organization?.name || organizationApikey

  const senderIsNotMe = senderProfile?.did !== myPublicProfileDid
  const senderName = senderIsNotMe ? (senderProfile?.displayName || senderProfile?.username)?.substring(0, 10) : 'You'

  const isDirectChat = chatChannel.memberUserDids?.length === 2 && !chatChannel.isGroupChat
  const otherMemberProfile = publicProfiles.find(profile => myPublicProfile.did !== profile.did)

  const isChannelNameIsDM = (
    chatChannel.name === 'unnamed chat' ||
    !chatChannel.organizationApikey &&
    chatChannel.memberUserDids?.length === 2 &&
    !chatChannel.isGroupChat
  )

  const userChatName = otherMemberProfile?.displayName || otherMemberProfile?.username
  const chatChannelName = chatChannel.name


  const chatImage = chatChannel.imageUrl
  return <ChatListMember {...{
    ...props,
    organization,
    myPublicProfile,
    isSelected,
    forwarding,
    onToggleSelection,
    name: isDirectChat && isChannelNameIsDM ? userChatName : chatChannelName,
    orgName,
    orgAvatar: organization?.consumer_icon,
    avatar: isDirectChat ? <EndUserAvatar {...{
      publicProfile: otherMemberProfile,
      size: 'md'
    }} /> : <EndUserDMAvatar {...{
      publicProfiles,
      chatImage,
      size: 'md'
    }} />,
    uid: chatChannel.uid,
    senderName,
    publicProfiles,
  }} />
}
