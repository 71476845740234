import { h, Fragment } from "preact"
import { useState, useMemo } from "preact/hooks"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import PeopleList from "components/PeopleList"
import { usePublicProfiles } from "lib/membershipAppStateHooks"
import { useAddMembersToChatChannel } from "lib/chatHooks"
import { useMyPublicProfileDid } from "resources/auth"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import IconButton from "components/IconButton"
import SearchInput from "components/SearchInput"
import { useChatChannel } from "lib/chatHooks"
import { useManageConnections } from "lib/connectionsAppstateHooks"
import PropTypes from "prop-types"
import "./index.sass"
import { useOrganizationMembershipInvites } from "lib/membershipAppStateHooks"
import { useMembershipsForOrganization } from "lib/membershipAppStateHooks"
import Alert from "components/Alert"


export default function AddChatMembersModal({ open, onClose, chatChannelUid }) {
  const [searchTerm, setSearchTerm] = useState("")
  const {
    chatChannel,
  } = useChatChannel(chatChannelUid, 'AddChatMembersModal')
  const [selectedMembers, setSelectedMembers] = useState(new Set())
  const addMembersToChatChannel = useAddMembersToChatChannel(
    "AddChatMembersModal"
  )
  const myPublicProfileDid = useMyPublicProfileDid()
  const { connections } = useManageConnections("AddChatMembersModal")
  const { organizationMembershipInvites } = useOrganizationMembershipInvites("AddChatMembersModal")
  const { memberships } = useMembershipsForOrganization(chatChannel.organizationApikey, "AddChatMembersModal")
  const memberUserDids = chatChannel?.memberUserDids || []
  const [alert, setAlert] = useState(null)

  const filteredMembersUserDids = memberships
    .filter((did) => did.memberUserDid !== myPublicProfileDid)
    .map(membership => membership.memberUserDid)

  const filteredConnections = (connections || [])
    .map(record => (record.user_did !== myPublicProfileDid ? record.user_did : record.friend_did))
    .filter(did => did !== myPublicProfileDid)

  const filteredMembers = [...new Set([...filteredMembersUserDids, ...filteredConnections])]
  const { publicProfiles } = usePublicProfiles(
    [...filteredMembers],
    "AddChatMembersModal"
  )
  const { publicProfiles: chatPublicProfiles } = usePublicProfiles([...memberUserDids], "AddChatMembersModal")

  const isAcceptedConnection = (did) => {
    return Array.isArray(connections) && connections.some(
      (connection) =>
        (connection.user_did === did || connection.friend_did === did) &&
        connection.request_status === "accepted"
    )
  }

  const toggleMemberSelection = (memberUserDid) => {
    setSelectedMembers((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(memberUserDid)) {
        newSet.delete(memberUserDid)
      } else {
        newSet.add(memberUserDid)
      }
      return newSet
    })
  }

  const handleAddMembers = async () => {
    const memberUserDids = Array.from(selectedMembers)
    try {
      await addMembersToChatChannel(chatChannelUid, memberUserDids)
      setSelectedMembers(new Set())
      onClose()
    } catch (error) {
      console.error("Failed to add members:", error)
    }
  }

  const filteredProfiles = useMemo(
    () =>
      publicProfiles.filter((profile) =>
        profile.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [publicProfiles, searchTerm]
  )

  const handleInviteUser = async (user) => {
    try {
      const invites = await organizationMembershipInvites({
        organizationApikey: chatChannel.organizationApikey,
        memberUserDids: [user.did],
      })
      if (invites && invites.organizationMembershipInvites
        .length > 0) {
        setAlert({
          type: 'success',
          title: 'Invitation Sent',
          message: `Invitation to ${user.did} was successful.`,
        })
      } else {
        setAlert({
          type: 'error',
          title: 'Invitation Failed',
          message: `Failed to invite ${user.did}. Please try again.`,
        })
      }
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Invitation Failed',
        message: `An error occurred while inviting ${user.did}: ${error.message}`,
      })
      console.error('Error inviting user:', error)
    }
  }

  const people = filteredProfiles.map((publicProfile) => {
    const isInChatProfiles = chatPublicProfiles.some((profile) => profile.did === publicProfile.did)
    const selected = isInChatProfiles ? true : selectedMembers.has(publicProfile.did)
    const onClick = () => toggleMemberSelection(publicProfile.did)
    const isConnected = isAcceptedConnection(publicProfile.did)

    const isAdmin = memberships.some(membership =>
      membership.memberUserDid === myPublicProfileDid && membership.admin === true
    )

    const shouldBeGrayedOut = isInChatProfiles || !isConnected
    let tooltipMessage = ""
    if (isInChatProfiles) {
      tooltipMessage = "is a member of the chat"
    }
    if (!isConnected) {
      tooltipMessage += tooltipMessage ? " Not a connection." : "Not a connection."
    }
    const showButton = shouldBeGrayedOut && isAdmin && !isInChatProfiles
    return {
      ...publicProfile,
      selected: selected,
      showButton,
      tooltipMessage,
      buttons: (
        <IconButton
          type={selected ? "selected" : "create"}
          onClick={onClick}
          style={{ cursor: shouldBeGrayedOut ? "not-allowed" : "pointer" }}
        />
      ),
      style: shouldBeGrayedOut ? { opacity: 0.5, cursor: "not-allowed", position: "relative" } : {},
    }
  })


  return (
    <>
      {alert && (
        <Alert
          type={alert.type}
          title={alert.title}
          onDismiss={() => setAlert(null)}
          position="right-top"
        >
          {alert.message}
        </Alert>
      )}

      <TransitionGroup component={null}>

        {open && (
          <CSSTransition
            timeout={300}
            classNames="modal-transition"
            unmountOnExit
          >

            <div className="AddChatMembersModal">
              <div className="AddChatMembersModal-header">
                <IconButton
                  type="return"
                  onClick={onClose}
                  className="AddChatMembersModal-header-returnButton"
                />
                <h2>Add Members</h2>
              </div>
              <SearchInput
                {...{
                  placeholder: "Search…",
                  className: "AddChatMembersModal-SearchBar",
                  interfaceHelp: {
                    position: "tl",
                    inside: true,
                    title: "Search",
                    content: `Search Members`,
                  },
                  value: searchTerm,
                  onChange: setSearchTerm,
                }}
              />
              <div className="AddChatMembersModal-content">

                <PeopleList people={people}
                  buttonLabel="Invite"
                  onButtonClick={handleInviteUser}
                  newWindow
                  style={{ paddingRight: "10px" }} />
              </div>

              <div className="AddChatMembersModal-actions">
                <ButtonRow>
                  <Button type="normal" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleAddMembers}
                    disabled={selectedMembers.size === 0}
                  >
                    Add
                  </Button>
                </ButtonRow>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>

  )
}

AddChatMembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatChannelUid: PropTypes.string.isRequired,
}
