import { h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import PropTypes from "prop-types"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import { useChatChannel, useAddChatChannelDetails } from "lib/chatHooks"
import useAssetUpload from "lib/useAssetUploadHook"
import RemoveButton from "components/RemoveButton"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import IconButton from "components/IconButton"
import Icon from "components/Icon"

import "./index.sass"

export default function EditChatInfoModal({ open, onClose, chatChannelUid}) {

  const { chatChannel } = useChatChannel(chatChannelUid, "EditChatInfoModal")

  const [chatName, setChatName] = useState("")
  const [description, setDescription] = useState("")
  const [previewUrl, setPreviewUrl] = useState("")
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(null)
  const [avatarFile, setAvatarFile] = useState(null)
  const [imageRemoved, setImageRemoved] = useState(false)
  const addChatChannelDetails = useAddChatChannelDetails(chatChannelUid, "EditChatInfoModal")
  const { assetUrl } = useAssetUpload("EditChatInfoModal")

  useEffect(() => {
    if (chatChannel) {
      setChatName(chatChannel.name)
      setDescription(chatChannel.description)
      setPreviewUrl(chatChannel.imageUrl)
    }
  }, [chatChannel])

  const handleChatNameChange = (event) => {
    setChatName(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleAvatarChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setAvatarFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
        setImageRemoved(false)
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (assetUrl && !imageRemoved) {
      setPreviewUrl(assetUrl)
    }
  }, [assetUrl, imageRemoved])

  const handleRemoveAvatar = () => {
    setPreviewUrl("")
    setAvatarFile(null)
    setImageRemoved(true)
  }

  const handleSave = async () => {
    setUploading(true)
    try {
      await addChatChannelDetails(
        chatChannelUid,
        chatName,
        avatarFile,
        description
      )
    } catch (err) {
      console.error("Error updating channel details", err)
      setError(err.message)
    } finally {
      setUploading(false)
      onClose()
    }
  }

  console.log(previewUrl, 'mehnn')

  const uploadInputRef = useRef()
  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div className="EditChatInfoModal">
            <div className="EditChatInfoModal-header">
              <IconButton
                {...{
                  type: "return",
                  onClick: onClose,
                  className: "EditChatInfoModal-header-returnButton",
                }}
              />
              <span
                style={{
                  fontSize: "24px",
                  marginLeft: "10px",
                  fontWeight: "600",
                }}
              >
                Edit Chat Info
              </span>
            </div>
            <div className="EditChatInfoModal-content">
              <div className="EditChatInfoModal-form">
                <div className="edit-group-field">
                  <label htmlFor="group-name">Chat Name </label>
                  <input
                    id="group-name"
                    type="text"
                    value={chatName}
                    onInput={handleChatNameChange}
                    className="EditChatInfoModal-input"
                  />
                </div>

                <div className="edit-group-field">
                  <label htmlFor="chat-avatar">Chat Image </label>

                  {previewUrl ? (
                    <div className="image-preview-container">
                      {previewUrl &&
                          (previewUrl.startsWith("#") ? (
                            <div
                              className="avatarImg EditChatInfoModal-preview"
                              style={{backgroundColor: previewUrl}}
                            >
                              <Icon type="chaty" />
                            </div>
                          ) : (
                            <img
                              src={previewUrl}
                              alt="Chat Image"
                              className="avatarImg EditChatInfoModal-preview"
                              style={{padding: 0}}
                            />
                          ))}
                      <RemoveButton
                        onClick={handleRemoveAvatar}
                        className="EditChatInfoModal-removeImage"
                      />
                    </div>
                  ) : (
                    <div
                      className="image-upload-container"
                      onMouseEnter={() => {}}
                      onMouseLeave={() => {}}
                    >
                      <button
                        style={{
                          height: "50px",
                          width: "50px",
                          marginRight: "5px",
                          backgroundColor: "rgb(51,49,101)",
                          borderRadius: "10%",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48.75 45.114"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#ffffff"
                            d="
                        M41.37,5.59
                        H38.84
                        A9.962,9.962,0,0,0,29.89,0
                        H9.98
                        A10,10,0,0,0,0,9.98
                        V23.26
                        a9.994,9.994,0,0,0,5.69,9
                        L4.64,35.71
                        a4.975,4.975,0,0,0,2.1,5.67
                        a4.913,4.913,0,0,0,2.68.78
                        a4.981,4.981,0,0,0,3.35-1.28
                        l4.6-4.15
                        H29.09
                        a.558.558,0,0,1,.22.03
                        c.09.03.14.05.18.06
                        l10.342,7.974
                        a1.238,1.238,0,0,0,1.49.13
                        a1.248,1.248,0,0,0,.52-1.41
                        L40.58,37.4
                        a.83.83,0,0,1,.79-.67
                        a7.379,7.379,0,0,0,7.38-7.37
                        V12.97
                        A7.375,7.375,0,0,0,41.37,5.59
                        Z
                        M11.94,34.88
                        L9.42,37.16
                        l1.17-3.85
                        l1.04-3.42
                        a1.66,1.66,0,0,0-1.65-1.66
                        a3.313,3.313,0,0,1-.55-.04
                        A4.956,4.956,0,0,1,5,23.26
                        V9.98
                        A4.987,4.987,0,0,1,9.98,5
                        H29.89
                        a4.905,4.905,0,0,1,2.35.59
                        a5,5,0,0,1,2.62,4.39
                        V23.26
                        a4.976,4.976,0,0,1-4.97,4.97
                        H19.93
                        a1.521,1.521,0,0,0-.47.07
                        a1.583,1.583,0,0,0-.45.21
                        Z"
                          />
                        </svg>
                      </button>
                      <button
                        className="upload-icon-and-text"
                        onClick={() => uploadInputRef.current.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          id="upload"
                          width="16"
                          height="16"
                        >
                          <path
                            d="
                        M8.71,7.71,
                        L11,5.41
                        V15
                        a1,1,0,0,0,2,0
                        V5.41
                        l2.29,2.3
                        a1,1,0,0,0,1.42,0
                        a1,1,0,0,0,0-1.42
                        l-4-4
                        a1,1,0,0,0-.33-.21
                        a1,1,0,0,0-.76,0
                        a1,1,0,0,0-.33.21
                        l-4,4
                        A1,1,0,1,0,8.71,7.71
                        Z
                        M21,14
                        a1,1,0,0,0-1,1
                        v4
                        a1,1,0,0,1-1,1
                        H5
                        a1,1,0,0,1-1-1
                        V15
                        a1,1,0,0,0-2,0
                        v4
                        a3,3,0,0,0,3,3
                        H19
                        a3,3,0,0,0,3-3
                        V15
                        A1,1,0,0,0,21,14
                        Z
                      "
                          />
                        </svg>
                        <span style={{ fontSize: "10px", marginTop: "0px" }}>
                          Upload
                        </span>
                      </button>
                    </div>
                  )}

                  <input
                    id="chat-avatar"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleAvatarChange}
                    ref={uploadInputRef}
                  />
                </div>

                <div className="edit-group-field">
                  <label htmlFor="group-name">Description</label>
                  <textarea
                    id="group-name"
                    type="text"
                    rows="5"
                    placeholder="Add chat description"
                    value={description}
                    onChange={handleDescriptionChange}
                    className="EditChatInfoModal-textarea"
                  />
                </div>

                {error && <div className="error-message">{error}</div>}
              </div>

              <div className="EditChatInfoModal-actions">
                <ButtonRow>
                  <Button type="normal" value="Cancel" onClick={onClose} />
                  <Button
                    type="primary"
                    value="Save"
                    onClick={handleSave}
                    disabled={uploading}
                  />
                </ButtonRow>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

EditChatInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatChannelUid: PropTypes.string.isRequired,
}
