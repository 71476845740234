import { h } from 'preact'
import './index.sass'
import EndUserAvatar from 'components/EndUserAvatar'

export default function MentionsMenu({ mentions, onClick }) {
  return (
    <div className="MentionsMenu">
      {mentions.map((mention) => (
        <div key={mention.did} onClick={() => onClick(mention)} className="MentionsMenu-item">
          <EndUserAvatar {...{
            publicProfile: mention,
            size: 'tn',
            className: 'MentionsMenu-item-avatar'
          }}
          />
          <span className="MentionsMenu-item-username">
            {mention.displayName || mention.username}
          </span>
        </div>
      ))}
    </div>
  )
}
