import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'
import { useRef, useErrorBoundary } from 'preact/hooks'

import classNames from 'lib/classNames'
import { useEditOrganization } from 'lib/organizationAdminHooks'
import { useLocalStorageState } from 'lib/storageHooks'
import pathnameMatcher from 'lib/pathnameMatcher'
import { useScrollIntoView } from 'lib/scrollHooks'

import Header from 'components/Header'
import Icon from 'components/Icon'
import Link from 'components/Link'
import LeftDrawer from 'components/LeftDrawer'
import SingleLine from 'components/SingleLine'
import PageNotFound from 'components/PageNotFound'
import ErrorMessage from 'components/ErrorMessage'

import NameAndBrandingPage from './NameAndBrandingPage'
import ContactAndBasicInfoPage from './ContactAndBasicInfoPage'
import VisibilityMembershipPage from './VisibilityMembershipPage'
// import SubscriptionsPage from './SubscriptionsPage'
import MyNetworkPage from './MyNetworkPage'
import OtherNetworksPage from './OtherNetworksPage'
// import WebhooksPage from './WebhooksPage'
import './index.sass'
import DeleteHubPage from './DeleteHubPage'

const PAGES = [
  {
    href: '/branding',
    route: '/branding',
    name: 'Name & Branding',
    component: NameAndBrandingPage,
  },
  {
    href: '/info',
    route: '/info',
    name: 'Contact & Basic Info',
    component: ContactAndBasicInfoPage,
  },
  {
    href: '/privacy',
    route: '/privacy',
    name: 'Visibility & Membership',
    component: VisibilityMembershipPage,
  },
  // {
  //   href: '/subscriptions',
  //   route: '/subscriptions/(find)?',
  //   name: 'Subscriptions',
  //   component: SubscriptionsPage,
  // },
  {
    href: '/network',
    route: '/network/(invite)?',
    name: 'My Network',
    component: MyNetworkPage,
    ifCanBeNetwork: true,
  },
  {
    href: '/networks',
    route: '/networks/(invites)?',
    name: 'Other Networks',
    component: OtherNetworksPage,
  },
  {
    href: '/deletehub',
    route: '/deletehub',
    name: 'Delete Hub',
    component: DeleteHubPage,

  },
  // 'ADVANCED',
  // {
  //   href: '/webhooks',
  //   route: '/webhooks/:path*',
  //   name: 'Webhooks',
  //   component: WebhooksPage,
  // },
]

export default function OrganizationAdminPage(props){
  const { params: { path }, organization } = props
  const canBeANetwork = !organization.is_private || organization.publicly_listed
  const pages = PAGES.filter(p =>
    p.ifCanBeNetwork ? canBeANetwork : true
  )
  const currentPage = path && pages.find(p =>
    p && p.route && pathnameMatcher.match('/' + path, p.route)
  )

  const rootPathname = `/${organization.apikey}/admin`

  const { updatingOrganization } =
    useEditOrganization(organization.apikey, 'OrganizationAdminPage')

  const [changesPerPage, setChangesPerPage] = useLocalStorageState(
    `OrganizationAdminPage:changes:${organization.apikey}`
  )

  let changes, setChanges
  if (currentPage){
    changes = changesPerPage[currentPage.href] || {}
    setChanges = changes => {
      if (!changes || Object.keys(changes).length === 0) changes = undefined
      setChangesPerPage({ [currentPage.href]: changes })
    }
  }
  const rootRef = useRef()
  useScrollIntoView({ref: rootRef}, [currentPage])

  return <LeftDrawer {...{
    ref: rootRef,
    className: 'OrganizationAdminPage',
    open: !!currentPage,
    menu: <PagesList {...{
      pages,
      rootPathname,
      currentPage,
      changesPerPage,
      updatingOrganization,
    }}/>,
    content: (
      (path && !currentPage) ? <PageNotFound recommendation={<span/>} /> :
      currentPage ? <Page {...{
        ...props,
        key: currentPage.name,
        canBeANetwork,
        rootPathname,
        currentPage,
        changes,
        setChanges,
      }}/> :
      <WelcomeScreen/>
    ),
  }}/>
}

function PagesList({
  pages, rootPathname, currentPage, updatingOrganization, changesPerPage
}){
  return <Fragment>
    <LeftDrawer.Header>
      <Icon type="cog" size="lg" spin={!!updatingOrganization} />
      <Header size="lg">Admin Settings</Header>
    </LeftDrawer.Header>
    {pages.map(page => typeof page === 'string'
      ? <Header
        className="OrganizationAdminPage-pageListHeader"
        centered
      ><span>{page}</span></Header>
      : <PageLink {...{
        name: page.name,
        href: `${rootPathname}${page.href}`,
        selected: page === currentPage,
        dirty: !!(changesPerPage && changesPerPage[page.href])
      }}/>
    )}
  </Fragment>
}

function PageLink({ name, href, selected, dirty }){
  return <Link {...{
    href,
    className: classNames('OrganizationAdminPage-PageLink', {
      selected, dirty,
    })
  }}>
    <Header size="lg">{name}</Header>
    <Icon type="circle" size="sm" />
  </Link>
}

function WelcomeScreen(){
  return <div className="OrganizationAdminPage-WelcomeScreen">
    <div>
      <Icon type="cog" size="lg"/>
      <Header size="lg">{PNFO.singular} Admin Settings</Header>
    </div>
  </div>
}

function Page({currentPage: { name, component }, ...props}){
  const [error, onDismiss] = useErrorBoundary()
  if (error) console.error(error)
  return <Fragment>
    <LeftDrawer.Header>
      <LeftDrawer.BackButton href={props.rootPathname} />
      <Header size="lg">
        <SingleLine>{name}</SingleLine>
      </Header>
    </LeftDrawer.Header>
    <LeftDrawer.Body>
      {error
        ? <ErrorMessage {...{error, onDismiss}}/>
        : h(component, props)
      }
    </LeftDrawer.Body>
  </Fragment>
}

