import { h } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  useMyOrganizations,
  useMyPublicProfile,
  usePublicProfiles,
} from 'lib/membershipAppStateHooks'

import IconButton from 'components/IconButton'
import NotificationBadge from 'components/NotificationBadge'
import Header from 'components/Header'
import IconRow from 'components/IconRow'
import IconRowList from 'components/IconRowList'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import EndUserAvatarStack from 'components/EndUserAvatarStack'
import ChatChannelListMember from 'components/ChatChannelListMember'
import './index.sass'
import Icon from 'components/Icon'

const ChatNotificationsList = memo(({ synopsis = [], onMemberClick }) => {
  const myPublicProfile = useMyPublicProfile()
  const { myOrganizations } = useMyOrganizations('ChatNotificationsList')

  const publicProfileDids = new Set()
  synopsis.forEach(chatChannel =>
    (chatChannel.memberUserDids || []).forEach(did => publicProfileDids.add(did))
  )
  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'ChatNotificationsList')

  const senderPublicProfileDids = new Set()

  synopsis.map(chatChannel => {
    if(chatChannel.senderUserDid)
      senderPublicProfileDids.add(chatChannel.senderUserDid)
  })

  const { publicProfiles: senderProfiles } = usePublicProfiles(senderPublicProfileDids, 'ChatNotificationsList')

  let chats = []

  synopsis.forEach(chatChannel => {
    let name, avatar, senderName, senderProfile
    if (!chatChannel.latestAt) return
    let organization = myOrganizations.find(o =>
      o.apikey === chatChannel.organizationApikey
    )
    if (!organization) organization = {apikey: chatChannel.organizationApikey}
    if (chatChannel.type === 'general'){
      avatar = <ChatChannelAvatar size="sm" value={
        chatChannel.imageUrl.startsWith('#') ? (
          <div className={'avatarImg'} style={{ backgroundColor: chatChannel.imageUrl }}>
            <Icon type="chaty" />
          </div>
        ) : (
          <img
            src={chatChannel.imageUrl}
            alt="Chat Image"
            className={'avatarImg'}
            style={{padding:0}}
          />
        )} />
      name = `${organization.name || chatChannel.organizationApikey}${chatChannel.type === 'general' && ' - General'}`
      senderProfile = senderProfiles.find(p => p.did === chatChannel.senderUserDid)
      const senderIsMe = senderProfile?.did === myPublicProfile.did
      senderName = senderIsMe ? 'You' : senderProfile?.displayName || senderProfile?.username
    }else{
      const otherPublicProfiles = chatChannel.memberUserDids
        .filter(did => did !== myPublicProfile.did)
        .map(did => publicProfiles.find(p => p.did === did))
      const isChannelNameIsDM = (
        chatChannel.name === 'unnamed chat' ||
        !chatChannel.organizationApikey &&
        chatChannel.memberUserDids?.length === 2 &&
        !chatChannel.isGroupChat
      )
      const isDirectChat = chatChannel.memberUserDids?.length === 2 && !chatChannel.isGroupChat
      const otherMemberProfile = publicProfiles.find(profile => myPublicProfile.did !== profile.did)
      const organizationApikeyName = organization && organization.apikey ? `${
        organization.apikey.substring(0, 20)
      }${organization.apikey.length > 20 ? '...' : ''} - ` : ''
      const userChatNames = otherMemberProfile?.displayName || otherMemberProfile?.username
      const userChatName = `${organizationApikeyName}${userChatNames?.substring(0, 20)}${
        userChatNames?.length > 20 ? '...' : ''
      }`
      avatar = <EndUserAvatarStack size="sm" publicProfiles={otherPublicProfiles} />
      const chatChannelName = chatChannel.name.substring(0, 20) + (chatChannel.name.length > 20 ? '...' : '')
      name = isDirectChat && isChannelNameIsDM ? userChatName : `${organizationApikeyName}${chatChannelName}`
      senderProfile = senderProfiles?.find(p => p.did === chatChannel.senderUserDid)
      const senderIsMe = senderProfile?.did === myPublicProfile.did
      senderName = senderIsMe ? 'You' : (senderProfile?.displayName || senderProfile?.username || '').substring(0, 10)
    }
    const date = moment(chatChannel.latestAt || chatChannel.createdAt).toDate().getTime()
    const unread = chatChannel.unread || 0
    const { preview } = chatChannel

    chats.push({
      key: chatChannel.uid,
      href: `/chat/${chatChannel.uid}`,
      row: <div style={{width: '100%'}}><ChatChannelListMember {...{
        date,
        preview,
        avatar,
        name,
        href: `/chat/${chatChannel.uid}`,
        isNotification: true,
        senderName,
        publicProfiles,
        myPublicProfile,
      }}/></div>,
      unread,
      date,
      buttons: (unread > 0 && <NotificationBadge total={unread}/>)
    })
  })

  chats = chats.sort(sortChats)

  return <div className="ChatNotificationsList">
    {chats.length === 0
      ? <Header size="sm" centered italic>You have no chat messages.</Header>
      : <IconRowList>
        {chats.map(({ key, href, row, buttons }) =>
          <IconRow {...{key, href, row, buttons, onClick: onMemberClick}}/>
        )}
      </IconRowList>
    }
  </div>
})

ChatNotificationsList.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: IconButton.propTypes.children,
}

export default ChatNotificationsList

function sortChats(a, b){
  if (a.unread > 0 && b.unread === 0) return -1
  if (a.unread === 0 && b.unread > 0) return 1
  return b.date - a.date
}
