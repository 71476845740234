import { useAppState } from 'lib/appState'
import { useEffect } from 'preact/hooks'

export function useMyExternalIdentities(componentName) {
  const {
    appAction,
    externalIdentities,
    gettingExternalIdentities,
    gettingExternalIdentitiesError,
  } = useAppState(
    [
      'externalIdentities',
      'gettingExternalIdentities',
      'gettingExternalIdentitiesError',
    ],
    componentName,
  )

  const loadExternalIdentities = appAction('externalIdentities.load')

  useEffect(
    () => {
      if (!externalIdentities) loadExternalIdentities()
    },
    [externalIdentities]
  )

  return {
    externalIdentities,
    gettingExternalIdentities,
    gettingExternalIdentitiesError,
    loadExternalIdentities,
  }
}

export function useManageMyExternalIdentities(componentName) {
  const {
    appAction,
    addingExternalIdentity,
    addingExternalIdentityError,
    verifiedExternalIdentity,
    verifyingExternalIdentity,
    errorVerifyingExternalIdentity,
    sendingExternalIdentityVerificationCode,
    externalIdentityVerificationCodeSent,
    resendExternalIdentityVerificationCodeError,
    deletingExternalIdentity,
    deletingExternalIdentityError,
  } = useAppState(
    [
      'addingExternalIdentity',
      'addingExternalIdentityError',
      'verifiedExternalIdentity',
      'verifyingExternalIdentity',
      'errorVerifyingExternalIdentity',
      'sendingExternalIdentityVerificationCode',
      'externalIdentityVerificationCodeSent',
      'resendExternalIdentityVerificationCodeError',
      'deletingExternalIdentity',
      'deletingExternalIdentityError',
    ],
    componentName,
  )

  const addExternalIdentity = appAction('externalIdentities.add')
  const deleteExternalIdentity = appAction('externalIdentities.delete')
  const verifyExternalIdentity = appAction('externalIdentities.verify')
  const resendVerificationCode = appAction('externalIdentities.resendVerificationCode')

  return {
    addExternalIdentity,
    addingExternalIdentity,
    addingExternalIdentityError,
    verifyExternalIdentity,
    verifiedExternalIdentity,
    verifyingExternalIdentity,
    errorVerifyingExternalIdentity,
    sendingExternalIdentityVerificationCode,
    resendVerificationCode,
    externalIdentityVerificationCodeSent,
    resendExternalIdentityVerificationCodeError,
    deleteExternalIdentity,
    deletingExternalIdentity,
    deletingExternalIdentityError,
  }
}

export function useHasVerifiedMobile(componentName){
  const { externalIdentities } = useMyExternalIdentities(componentName)
  if (externalIdentities === undefined) return 'loading'
  console.log(externalIdentities)
  return Object.values(externalIdentities)
    .some(ei => ei.type === 'mobile' && ei.verified)
}
