import { h } from 'preact'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import { ChatListMember } from './ChatListMember'
import './index.sass'
import { useMyOrganizations, useMyPublicProfile, usePublicProfile,
  usePublicProfiles } from 'lib/membershipAppStateHooks'
import Icon from 'components/Icon'


export function GeneralChatListMember({ senderUserDid, memberUserDids, ...props }) {

  const { myOrganizations } = useMyOrganizations('OrganizationChatPage')
  const orgName = myOrganizations.find(org => org.apikey === props.organizationApikey)?.name || props.organizationApikey
  const organization = myOrganizations.find(org => org.apikey === props.organizationApikey)

  const myPublicProfile = useMyPublicProfile('GeneralChatListMember')
  const { publicProfile: senderProfile } = usePublicProfile(senderUserDid, 'OrganizationChatPage')

  const { publicProfiles } = usePublicProfiles(memberUserDids, 'OrganizationChatPage')

  const senderIsNotMe = senderProfile?.did !== myPublicProfile.did
  const senderName = senderIsNotMe ? (senderProfile?.displayName || senderProfile?.username) : 'You'

  return <ChatListMember {...{
    ...props,
    organization,
    myPublicProfile,
    publicProfiles,
    name: `General`,
    orgName,
    senderName,
    avatar: <ChatChannelAvatar size="sm" value={
      props.imageUrl.startsWith('#') ? (
        <div className={'avatarImg'} style={{ backgroundColor: props.imageUrl }}>
          <Icon type="chaty" />
        </div>
      ) : (
        <img
          src={props.imageUrl}
          alt="Chat Image"
          className={'avatarImg'}
          style={{padding:0}}
        />
      )}
    />
  }} />
}
