import { isLoggedIn } from "../resources/auth"
import api from "../api"
import { findTopicOrSubtopic } from "lib/topics"
import { loadEntity } from "lib/actionHelpers"

export async function loadMyOrganizationsTopics(organizationApikeys) {

  const myOrganizationsTopicsKey = 'myOrganizationsTopics'
  const myOrganizationsTopicsLoadingErrorKey = 'myOrganizationsTopicsLoadingError'

  if (!organizationApikeys.length) {
    console.error("No organizations provided for loading topics.")
    return
  }

  try {
    const results = await Promise.all(organizationApikeys.map(apikey =>
      api.getOrganizationTopics(apikey)
    ))


    const topicsByOrganization = organizationApikeys.reduce((acc, apikey, index) => {
      acc[apikey] = results[index]
      return acc
    }, {})

    this.setState({
      [myOrganizationsTopicsKey]: topicsByOrganization,
      [myOrganizationsTopicsLoadingErrorKey]: null
    })

    return topicsByOrganization
  } catch (error) {
    this.setState({
      [myOrganizationsTopicsKey]: null,
      [myOrganizationsTopicsLoadingErrorKey]: error
    })
  }
}

export async function loadOrganizationTopics(organizationApikey) {
  if (!isLoggedIn()) return
  let activeTopic
  const topicsKey = `organizationTopics:${organizationApikey}`
  const topicKey = `organization:${organizationApikey}:activeTopic`

  this.setState({ [topicsKey]: null })

  try {
    const organizationTopics = await api.getOrganizationTopics(organizationApikey)
    this.setState({ [topicsKey]: organizationTopics })

    if (organizationTopics.length > 0 && !activeTopic) {
      activeTopic = organizationTopics.find(topic => !topic.parent_uid && !topic.is_hidden && !topic.is_category)
      this.setState({ [topicKey]: activeTopic })
    }
  } catch (error) {
    this.setState({ [topicsKey]: [] })
  }
}

export async function createTopic(organizationApikey, topic) {
  const organizationTopicsKey = `organizationTopics:${organizationApikey}`
  try {
    const newTopic = await api.createOrganizationTopic(organizationApikey, topic)
    this.setState({
      [`organization:${organizationApikey}:createdTopic`]: newTopic,
      [organizationTopicsKey]: [...this.getState()[organizationTopicsKey], ...newTopic]
    })
    return newTopic
  } catch (error) {
    console.error('Error creating topic', error)
  }
}
export async function createSubtopic(organizationApikey, parentUid, subtopic) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const createdSubtopicErrorKey = `organization:${organizationApikey}:createdSubtopicError`
  const initialState = this.getState()
  const organizationTopics = initialState[topicsKey] || []

  const parentTopicIndex = organizationTopics.findIndex(topic => topic.uid === parentUid)
  if (parentTopicIndex === -1 && parentUid !== null) {
    console.error('Parent topic not found')
    return
  }

  const existingParentTopic = organizationTopics[parentTopicIndex]

  try {
    const newSubtopic = await api.createOrganizationTopic(organizationApikey, {
      ...subtopic,
      parent_uid: parentUid,
    })

    const updatedParentTopic = {
      ...existingParentTopic,
      subtopics: existingParentTopic.subtopics ? [...existingParentTopic.subtopics, ...newSubtopic] : [...newSubtopic],
    }

    const updatedTopics = [
      ...organizationTopics.slice(0, parentTopicIndex),
      updatedParentTopic,
      ...organizationTopics.slice(parentTopicIndex + 1),
    ]

    this.setState({
      [topicsKey]: updatedTopics,
      [`organization:${organizationApikey}:createdSubtopic`]: newSubtopic,
      [createdSubtopicErrorKey]: null,
    })

    return newSubtopic
  } catch (error) {
    console.error('Error creating subtopic', error)
    this.setState({
      [createdSubtopicErrorKey]: error,
    })
    throw error
  }
}

export async function createTopicAndSubtopic(organizationApikey, topic, subtopic) {
  const organizationTopicsKey = `organizationTopics:${organizationApikey}`
  const createdTopicErrorKey = `organization:${organizationApikey}:createdTopicError`
  const createdSubtopicErrorKey = `organization:${organizationApikey}:createdSubtopicError`

  console.log('createTopicAndSubtopic', organizationApikey, topic, subtopic)

  try {
    const newTopic = await api.createOrganizationTopic(organizationApikey, topic)
    if (!newTopic || !newTopic[0].uid) {
      throw new Error("Failed to create topic or missing UID")
    }

    const newSubtopic = await api.createOrganizationTopic(organizationApikey, {
      ...subtopic,
      parent_uid: newTopic[0].uid
    })

    const currentTopics = this.getState()[organizationTopicsKey] || []
    this.setState({
      [`organization:${organizationApikey}:createdTopic`]: newTopic,
      [`organization:${organizationApikey}:createdSubtopic`]: newSubtopic,
      [organizationTopicsKey]: [...currentTopics, ...newTopic.map(t => ({ ...t, subtopics: newSubtopic }))]
    })

    return { topic: newTopic, subtopic: newSubtopic }

  } catch (error) {
    console.error('Error creating topic and subtopic', error)
    this.setState({
      [createdTopicErrorKey]: error,
      [createdSubtopicErrorKey]: error
    })
    throw error
  }
}


export async function editTopic(organizationApikey, topicUid, changes) {
  const topicsKey = `organizationTopics:${organizationApikey}`

  try {
    await api.updateOrganizationTopic(organizationApikey, topicUid, changes)

    const organizationTopics = this.getState()[topicsKey]
    const updatedOrganizationTopics = organizationTopics.map(topic => {
      if (topic.uid === topicUid) {
        return { ...topic, ...changes }
      }

      if (topic.subtopics) {
        const updatedSubtopics = topic.subtopics.map(subtopic => {
          if (subtopic.uid === topicUid) {
            return { ...subtopic, ...changes }
          }
          return subtopic
        })
        return { ...topic, subtopics: updatedSubtopics }
      }

      return topic
    })

    this.setState({ [topicsKey]: updatedOrganizationTopics })
  } catch (error) {
    console.error('Error editing topic', error)
  }
}

export async function deleteTopic(organizationApikey, topicUid) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const deleteTopicErrorKey = `organization:${organizationApikey}:deleteTopicError`
  const initialState = this.getState()
  const organizationTopics = initialState[topicsKey] || []

  const item = findTopicOrSubtopic(organizationTopics, topicUid)
  if (!item) {
    console.error('Topic or subtopic not found')
    return
  }

  try {
    await api.deleteOrganizationTopic(organizationApikey, topicUid)

    const updatedTopics = organizationTopics.filter(topic => topic.uid !== topicUid)
    this.setState({ [topicsKey]: updatedTopics })

  } catch (error) {
    console.error('Error deleting topic', error)
    this.setState({
      [deleteTopicErrorKey]: error
    })
  }
}

export async function transferTopicFeedPosts(organizationApikey, topicUid, newTopicUid) {
  const transferTopicFeedPostsErrorKey = `organization:${organizationApikey}:transferTopicFeedPostsError`

  try {
    await api.transferTopicFeedPosts(organizationApikey, topicUid, newTopicUid)

    this.setState({
      [transferTopicFeedPostsErrorKey]: null,
    })

  } catch (error) {
    this.setState({
      [transferTopicFeedPostsErrorKey]: error,
    })

    throw error
  }
}


// TODO return subtopic and its parent topic
export async function setActiveTopic(organizationApikey, topicUid) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const topicKey = `organization:${organizationApikey}:activeTopic`

  const organizationTopics = this.getState()[topicsKey]

  if (organizationTopics) {
    const activeTopic = findTopicOrSubtopic(organizationTopics, topicUid)
    if (activeTopic) {
      this.setState({ [topicKey]: activeTopic })
    }
  }
}

export async function loadDefaultTopic(organizationApikey) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const topicKey = `organization:${organizationApikey}:activeTopic`

  const organizationTopics = this.getState()[topicsKey]

  if (organizationTopics) {
    const activeTopic = organizationTopics.find(topic => !topic.parent_uid && !topic.is_hidden && !topic.is_category)
    this.setState({ [topicKey]: activeTopic })
  }
}

export async function updateTopicsOrder(organizationApikey, changes) {
  const topicsKey = `organizationTopics:${organizationApikey}`

  try {
    const updatedTopics = await api.updateOrganizationTopicOrder({
      organizationApikey, changes
    })

    console.log('Updated topics order:', updatedTopics)

    this.setState({ [topicsKey]: updatedTopics })

    return updatedTopics

  } catch (error) {
    console.error('Error updating topics order', error)
  }

}

export async function verifyUserTopicFollower(organizationApikey, topicUid) {
  if (!isLoggedIn()) return
  return loadEntity.call(this, {
    reload: true,
    entityKey: 'verifyUserTopicFollower',
    request: () => api.verifyUserFollowsOrganizationTopic(organizationApikey, topicUid)
  })
}

export async function followTopic(organizationApikey, topicUid) {
  const followTopicErrorKey = `organization:${organizationApikey}:followTopicError`

  try {
    await api.followOrganizationTopic(organizationApikey, topicUid)
    this.setState({ [followTopicErrorKey]: null })
    this.verifyUserTopicFollower(organizationApikey, topicUid)
  } catch (error) {
    this.setState({ [followTopicErrorKey]: error })
  }
}

export async function unFollowTopic(organizationApikey, topicUid) {
  const unFollowTopicErrorKey = `organization:${organizationApikey}:unFollowTopicError`

  try {
    await api.unFollowOrganizationTopic(organizationApikey, topicUid)
    this.setState({ [unFollowTopicErrorKey]: null })
    this.verifyUserTopicFollower(organizationApikey, topicUid)
  } catch (error) {
    this.setState({ [unFollowTopicErrorKey]: error })
  }
}

export async function toggleTopicFollow(organizationApikey, topicUid) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const topicKey = `organization:${organizationApikey}:activeTopic`

  const organizationTopics = this.getState()[topicsKey]
  const activeTopic = this.getState()[topicKey]

  if (!organizationTopics || !activeTopic) return

  const topic = findTopicOrSubtopic(organizationTopics, topicUid)

  if (!topic) return

  const isFollowing = await this.takeAction('topics.verifyUserTopicFollower', organizationApikey, topicUid)

  console.log('isFollowing action', {
    isFollowing,
    organizationApikey,
    topicUid
  })

  if (isFollowing) {
    this.takeAction('topics.unFollowTopic', organizationApikey, topicUid)
  } else {
    this.takeAction('topics.followTopic', organizationApikey, topicUid)
  }
}

export async function followAllTopics(organizationApikey) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const followAllTopicsKey = `organization:${organizationApikey}:followAllTopics`
  const followAllTopicsErrorKey = `organization:${organizationApikey}:followAllTopicsError`

  const topics = this.getState()[topicsKey]

  if (topics.length === 0) return

  this.setState({ [followAllTopicsKey]: true })

  try {
    const result = await api.followAllOrganizationTopics(organizationApikey)
    this.setState({ [followAllTopicsErrorKey]: null })
    this.verifyUserFollowsAllOrganizationTopics(organizationApikey)
    return result
  } catch (error) {
    this.setState({ [followAllTopicsErrorKey]: error })
  }

}

export async function unFollowAllTopics(organizationApikey) {
  const topicsKey = `organizationTopics:${organizationApikey}`
  const unFollowAllTopicsKey = `organization:${organizationApikey}:unFollowAllTopics`
  const unFollowAllTopicsErrorKey = `organization:${organizationApikey}:unFollowAllTopicsError`

  const topics = this.getState()[topicsKey] || []

  if (topics.length === 0) return

  this.setState({ [unFollowAllTopicsKey]: true })

  try {
    const result = await api.unFollowAllOrganizationTopics(organizationApikey)
    this.setState({ [unFollowAllTopicsErrorKey]: null })
    this.verifyUserFollowsAllOrganizationTopics(organizationApikey)
    return result
  } catch (error) {
    this.setState({ [unFollowAllTopicsErrorKey]: error })
  }
}

export async function verifyUserFollowsAllOrganizationTopics(organizationApikey) {
  if (!isLoggedIn()) return
  return loadEntity.call(this, {
    reload: true,
    entityKey: 'verifyUserFollowsAllOrganizationTopics',
    request: () => api.verifyUserFollowsAllOrganizationTopics(organizationApikey)
  })
}
