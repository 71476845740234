import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import { scrollIntoView } from 'lib/DOMHelpers'

import ContentBox from 'components/ContentBox'
import Link from 'components/Link'
import ErrorMessage from 'components/ErrorMessage'
import DropdownMenu from 'components/DropdownMenu'
import IconButton from 'components/IconButton'
import api from '../../a-portal/api/index'
import { useAppState } from 'lib/appState'
import Tooltip from 'components/Tooltip'
import { createPageAlert } from 'resources/pageAlerts'
import isMobile from 'lib/isMobile'


import './index.sass'

export default function FeedContentBox({
  disabled,
  error,
  modals,
  alert,
  icon,
  title,
  actions,
  truncateBody,
  body,
  bottom,
  post,
  currentUser,
  ...props
}){

  let className = 'FeedContentBox'
  if (props.className) className += ` ${props.className}`

  const { takeAction } = useAppState({}, 'PinnedPosts')


  const ref = useRef(null)
  const scrollTo = () => {
    setTimeout(() => {
      scrollIntoView(ref.current.base, 100)
    })
  }

  const scrollToPost = (postUid) => {
    setTimeout(() => {
      const postElement = document.querySelector(`[data-feed-post-uid="${postUid}"]`)
      if (postElement) {
        scrollIntoView(postElement, 100)
      }
    }, 300)
  }


  return <ContentBox {...{...props, ref, className}} >
    {modals}
    {alert}
    <ErrorMessage error={error} />
    <div className="FeedContentBox-top">
      {icon && <div className="FeedContentBox-icon">{icon}</div>}
      {title && <div className="FeedContentBox-title">{title}</div>}

      {post?.pinned ? (
        <Tooltip text={currentUser?.isAdmin ? "Unpin Post" : "Pinned Post"}>

          <IconButton
            type="pinnedIcon"
            className="pinIcon"
            onClick={!isMobile ? async () => {
              if (currentUser?.isAdmin) {
                takeAction('feedPosts.unpinPost', { post })
              }
            } : undefined}
          />
        </Tooltip>
      ) : (
        !isMobile && !post?.isDeleted &&
        window.location.pathname.includes('/forum') &&
        currentUser?.isAdmin &&
    <Tooltip text="Pin this post">
      <IconButton
        type="pinIcon"
        className="pinIcon"
        onClick={async () => {
          try {
            const response = await api.getPinnedPostsForOrganization({
              organizationApikey: post.feedOrganizationApikey
            })
            const pinnedPosts = response.pinnedPosts.pinnedPosts.pinnedPosts

            const currentTopicPins = pinnedPosts.filter(pinnedPost => {
              return pinnedPost.topicUid === post.topicUid
            })

            if (currentTopicPins.length >= 5) {
              createPageAlert({
                type: 'error',
                message: 'You can only pin five posts per topic at a time.'
              })
              return
            }
            await takeAction('feedPosts.pinPost', { post })
            scrollToPost(post.uid)
          } catch (err) {
            console.error('Pin API error:', err)
          }
        }}
      />
    </Tooltip>
      )}

      {actions && actions.length > 0 &&
        <DropdownMenu
          className="FeedContentBox-actions"
          disabled={disabled}
          options={[
            ...(isMobile && currentUser?.isAdmin ? [{
              value: post?.pinned ? 'Unpin Post' : 'Pin Post',
              onSelect: async () => {
                try {
                  if (post?.pinned) {
                    takeAction('feedPosts.unpinPost', { post })
                  } else {
                    const response = await api.getPinnedPostsForOrganization({
                      organizationApikey: post.feedOrganizationApikey
                    })
                    const pinnedPosts = response.pinnedPosts.pinnedPosts.pinnedPosts
                    const currentTopicPins = pinnedPosts.filter(pinnedPost =>
                      pinnedPost.topicUid === post.topicUid
                    )

                    if (currentTopicPins.length >= 5) {
                      createPageAlert({
                        type: 'error',
                        message: 'You can only pin five posts per topic at a time.'
                      })
                      return
                    }
                    takeAction('feedPosts.pinPost', { post })
                  }
                } catch (err) {
                  console.error('Pin API error:', err)
                }
              }
            }] : []),
            ...actions
          ]}
          rightAligned
        >
          <IconButton type="threeDotIcon"/>
        </DropdownMenu>
      }
    </div>
    {truncateBody ? <BodyTruncated {...{scrollTo, body}}/> : <Body {...{body}}/>}
    {bottom && <div className="FeedContentBox-bottom">{bottom}</div>}
  </ContentBox>
}

FeedContentBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: ErrorMessage.propTypes.error,
  modals: PropTypes.node,
  alert: PropTypes.node,
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  actions: DropdownMenu.propTypes.options,
  truncateBody: PropTypes.bool,
  body: PropTypes.node.isRequired,
  bottom: PropTypes.node,
  post: PropTypes.object,
  currentUser: PropTypes.object,
}


function Body({ className = '', body, children }){
  return <div className={`FeedContentBox-Body ${className}`}>
    <div className="FeedContentBox-Body-content">{body}</div>
    {children}
  </div>
}

function BodyTruncated({ body, scrollTo }){
  const ref = useRef(null)
  const [contentOverflows, setContentOverflows] = useState(false)
  const [showingMore, setShowingMore] = useState(false)
  const [hasMedia, setHasMedia] = useState(false)
  const toggleShowingMore = () => {
    setShowingMore(!showingMore)
    if (showingMore) scrollTo()
  }

  useEffect(
    () => {
      if (!ref.current || !ref.current.base) return
      const domNode = ref.current.base.querySelector('.FeedContentBox-Body-content')
      setContentOverflows(domNode.scrollHeight > domNode.clientHeight)
      const media = domNode.querySelector('img,video,iframe')
      setHasMedia(!!media)
      if (media) {
        media.addEventListener('load', () => {
          setContentOverflows(domNode.scrollHeight > domNode.clientHeight)
        })
      }
    },
    [ref.current && ref.current.base],
  )

  let className = 'FeedContentBox-Body-truncated'
  if (!hasMedia) className += ' FeedContentBox-Body-noMedia'
  if (showingMore) className += ' FeedContentBox-Body-showingMore'
  else if (contentOverflows) className += ' FeedContentBox-Body-overflows'

  return <Body {...{ ref, className, body }}>
    {contentOverflows &&
      <Link
        type="text"
        onClick={toggleShowingMore}
        className="FeedContentBox-Body-toggle"
      >
        Show {showingMore ? 'less' : 'more'}
      </Link>
    }
  </Body>
}
