import { h } from 'preact'
import { useRef, useEffect, useState, useCallback } from 'preact/hooks'
import isMobile from 'lib/isMobile'
import useOnlineStatus from 'lib/useOnlineStatusHook'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Form from 'components/Form'
import TextArea from 'components/TextArea'
import FileButton from 'components/FileButton'
import Picker from 'emoji-picker-react'
import RemoveButton from 'components/RemoveButton'
import { useUrlPreviews } from 'lib/feedPostHooks'
import { findUrls } from 'lib/urlHelpers'
import { FILE_ATTACHMENT_PREFIX } from './FILE_ATTACHMENT_PREFIX'
import Checkbox from 'components/Checkbox'
import { useForwardChatMessage } from 'lib/chatHooks'
import { FilePreviewModal } from './FilePreviewModal'
import ChatUrlPreview from 'components/ChatUrlPreview'
import './index.sass'

export function ChatMessageForm({
  createChatMessage,
  uploadFiles,
  disabled,
  isEditing = false,
  editedMessage,
  handleEditMessage,
  isThread,
  setReplyingTo,
  replyingTo,
  replyToMessage,
  chatChannelUid,
  handleCancelEdit,
  dmMute,
  generalMute,
  isOtherMemberAdmin,
  hasLeftChannel,
  forwardMessageUid,
  channelUidsToForwardTo,
  forwardedMessageContent,
  openforwardWindow,
  setOpenforwardWindow,
  setChatChip,
  setForwarding,
  editChatMessage,
  setIsEditing,
  setIsEditingThread,
  setEditedMessage,
  setEditMessageUid,
  editMessageUid,
  textAreaRef,
  inputStr,
  setInputStr,
  handleInput,
  setShowMentionDropDown,
  setMentionableProfiles,
  setSelectedMentions,
  publicProfiles
}) {

  const [showPicker, setShowPicker] = useState(false)

  const online = useOnlineStatus()
  if (!online || (generalMute && !isOtherMemberAdmin) || (dmMute && !isOtherMemberAdmin)) disabled = true
  disabled = disabled || (!online && !forwardedMessageContent)

  const messageUid = replyingTo?.uid

  const submittable = !disabled && inputStr?.length > 0
  const { loadUrlPreview, urlPreviews } = useUrlPreviews('ChatMessageForm')
  const { forwardMessage } = useForwardChatMessage('ChatMessageForm')
  const [currentURLPreview, setCurrentURLPreview] = useState(undefined)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [captions, setCaptions] = useState([])
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [sendUrlPreview, setSendUrlPreview] = useState(true)
  const timerRef = useRef(null)

  useEffect(() => {
    if(!inputStr) setCurrentURLPreview(undefined)
  }, [inputStr])

  const imageURL = replyingTo?.message?.url

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      const urls = findUrls(inputStr)

      if (urls.length === 0) {
        setCurrentURLPreview(undefined)
        return
      }

      const [url] = urls

      if (url in urlPreviews) {
        setCurrentURLPreview(urlPreviews[url])
      } else {
        loadUrlPreview(url)
      }
    }, 1000)

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [inputStr, urlPreviews])

  const onCloseUrlPreview = () => {
    setSendUrlPreview(false)
  }

  const replyToImageData = FILE_ATTACHMENT_PREFIX + JSON.stringify(replyingTo?.message)

  const isForwarding = forwardedMessageContent && openforwardWindow && channelUidsToForwardTo?.length > 0
  const [sendToMainChat, setSendToMainChat] = useState(false)


  const handleSaveMessage = useCallback(() => {
    if (editMessageUid) {
      const updatedMessage = { message: editedMessage, urlPreview: undefined }

      if (currentURLPreview && sendUrlPreview) {
        updatedMessage.urlPreview = currentURLPreview
      }
      editChatMessage(chatChannelUid, editMessageUid, updatedMessage)
      setIsEditing(false)
      setIsEditingThread(false)
      setEditedMessage('')
      setEditMessageUid(null)
    }
    setSendUrlPreview(true)
  }, [
    editChatMessage,
    chatChannelUid,
    editMessageUid,
    editedMessage,
    currentURLPreview,
    sendUrlPreview,
    setIsEditing,
    setIsEditingThread,
    setEditedMessage,
    setEditMessageUid
  ])

  const onSubmit = async () => {
    if (!submittable) return
    if (inputStr.length === 0) {
      return
    }
    for (let i = 0; i < publicProfiles.length; i++) {
      inputStr = inputStr.replace(`@${publicProfiles[i].username}`, `@${publicProfiles[i].did}`)
    }
    if (isForwarding) {
      try {
        await forwardMessage(channelUidsToForwardTo, forwardMessageUid, inputStr)
        console.log('Message forwarded successfully')
        setForwarding(false)
        setOpenforwardWindow(false)
        setChatChip([])
      } catch (error) {
        console.error('Error forwarding message:', error)
      }

      setCurrentURLPreview(undefined)

    } else if (isEditing) {
      handleSaveMessage()
    } else if (replyingTo) {
      replyToMessage(chatChannelUid, messageUid, {
        originalMessage: imageURL ? replyToImageData : replyingTo.message,
        replyMessage: inputStr,
        urlPreviewId: sendUrlPreview && currentURLPreview?.id,
        sendToMainChat
      })
      setReplyingTo(null)
      setSendToMainChat(false)
    } else {
      const messageData = {
        message: inputStr
      }
      if (currentURLPreview
       && sendUrlPreview
      ) {
        messageData.urlPreview = currentURLPreview
      } else {
        messageData.urlPreview = undefined
      }
      createChatMessage(messageData)
    }
    setInputStr('')
    setCurrentURLPreview(undefined)
    setSendUrlPreview(true)
    setShowMentionDropDown(false)
    setMentionableProfiles([])
    setSelectedMentions([])
  }

  const placeholderText = !online
    ? 'OFFLINE'
    : generalMute
      ? 'You are paused from General Chat'
      : dmMute
        ? 'You are paused from Private chat'
        : disabled
          ? ''
          : hasLeftChannel
            ? 'You left this chat group'
            : 'Message…'
  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData('text')
    const urls = findUrls(paste)
    if (urls.length > 0) {
      const [url] = urls
      loadUrlPreview(url)
    }
  }
  useEffect(() => {
    if (forwardedMessageContent && openforwardWindow) {
      setInputStr(forwardedMessageContent)
    }
  }, [forwardedMessageContent, openforwardWindow])


  useEffect(() => {
    if (isEditing) {
      handleInput(editedMessage)
    }
  }, [isEditing, editedMessage])

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (!isEditing) {
      setInputStr('')
    }
  }, [isEditing])


  const submitOnEnter = (event) => {
    if (!disabled && !isMobile && !event.metaKey && !event.shiftKey && event.key === 'Enter') {
      event.preventDefault()
      onSubmit()
    }
  }

  const onEmojiClick = (emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji)
    setShowPicker(false)
  }

  const handleFileSelection = (files) => {
    setSelectedFiles(files)
    const filesWithCaptions = files.map((file, index) => ({
      file,
      caption: captions[index] || '',
    }))
    setShowPreviewModal(true)
    return filesWithCaptions
  }

  const handleAddMoreFiles = (newFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles])
    setShowPreviewModal(true)
  }

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    const updatedCaptions = { ...captions }
    delete updatedCaptions[index]
    setCaptions(updatedCaptions)

    if (selectedFiles.length - 1 === 0) {
      setShowPreviewModal(false)
    }
  }
  const handleCaptionChange = (index, newCaption) => {
    const updatedCaptions = [...captions]
    updatedCaptions[index] = newCaption
    setCaptions(updatedCaptions)
  }

  const handleSubmit = () => {
    const filesWithCaptions = handleFileSelection(selectedFiles)
    uploadFiles(filesWithCaptions)
    setShowPreviewModal(false)
    setCaptions([])
  }
  return (
    <div>
      <div className="OrganizationChatPage-EmojiPicker-wrapper">
        <div className="OrganizationChatPage-EmojiPicker">
          {showPicker && (
            <Picker
              pickerStyle={{ width: "100%" }}
              onEmojiClick={onEmojiClick}
              emojiStyle="native"
            />
          )}
        </div>
      </div>

      {isEditing && (
        <div className="replyingWrapper">
          <div>
            <div onClick={handleCancelEdit} className="clearReplyButton">
              <RemoveButton />
            </div>
            <div>
              <div className="replyingContent">
                <span className="username">Editing</span>:<br />
                {currentURLPreview && (
                  <ChatUrlPreview {...{
                    ...currentURLPreview,
                    canClosed: true,
                    onCloseUrlPreview,
                    sendUrlPreview,
                  }} />
                )}
              </div>
              <span className="messageText">{editedMessage?.length > 50 ?
                `${editedMessage.substring(0, 50)}...` : editedMessage}</span>
            </div>
          </div>

        </div>
      )}
      {isThread && (
        <div className="OrganizationChatPage-ChatMessageForm-sendToMainChat">
          <Checkbox
            id="sendToMainChat"
            checked={sendToMainChat}
            onChange={() => setSendToMainChat((val) => !val)}
          />
          <label htmlFor="sendToMainChat">Send to main chat</label>
        </div>
      )}
      <Form
        className="OrganizationChatPage-ChatMessageForm"
        onSubmit={onSubmit}
        disabled={disabled}
      >
        {currentURLPreview && !isEditing && (
          <ChatUrlPreview {...{
            ...currentURLPreview,
            canClosed: true,
            onCloseUrlPreview,
            sendUrlPreview,
          }} />
        )}
        <FileButton
          className="FileButton"
          disabled={disabled || hasLeftChannel}
          type="subtle"
          onFiles={handleFileSelection}
          tabIndex={2}
        >
          <Icon type="upload" />
        </FileButton>
        {showPreviewModal && (
          <FilePreviewModal
            files={selectedFiles}
            caption={captions}
            onCaptionChange={handleCaptionChange}
            onAddMoreFiles={handleAddMoreFiles}
            onSubmit={handleSubmit}
            onRemoveFile={handleRemoveFile}
            onClose={() => setShowPreviewModal(false)}
          />
        )}
        <div className="textStyle">
          <TextArea
            className="TextArea"
            disabled={disabled || hasLeftChannel}
            ref={textAreaRef}
            autoResizeVertically
            minRows={1}
            maxRows={4}
            resize="none"
            placeholder={placeholderText}
            value={isEditing ? editedMessage : inputStr}
            onInput={isEditing && !hasLeftChannel ? handleEditMessage : handleInput}
            onKeyDown={submitOnEnter}
            tabIndex={1}
            style={{ borderRadius: '30px' }}
            onPaste={handlePaste} />
          <img
            className="EmojiIcon"
            disabled={disabled || hasLeftChannel}
            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
            onClick={() => setShowPicker((val) => !val)} />
        </div>
        <Button
          className={`SubmitButton ${submittable ? 'submittable' : ''}`}
          disabled={disabled || hasLeftChannel || !submittable}
          submit
          type="primary"
          value={isEditing ? <Icon type="checkmark" /> : <Icon type="send" />}
          tabIndex={3}
        />
      </Form>
    </div>
  )

}
