import { h } from 'preact'
import { useMemo, useEffect, useState, useRef, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import richMedia from 'lib/richMedia'
import useUploadAssets from 'lib/uploadAssetsHook'
import { useUrlPreviews } from 'lib/feedPostHooks'
import { findUrls } from 'lib/urlHelpers'
import FilesUploadDroparea from 'components/FilesUploadDroparea'
import Icon from 'components/Icon'
import FileButton from 'components/FileButton'
import TextArea from 'components/TextArea'
import FeedPostURLPreview from 'components/FeedPostURLPreview'
import MediaSlideshow from 'components/MediaSlideshow'
import PlainText from 'components/PlainText'
import RemoveButton from 'components/RemoveButton'
import './index.sass'

export default function RichMediaInput({
  disabled,
  className,
  value,
  onInput,
  onChange = onInput,
  placeholder = '',
  bigify = true,
  ...props
}){
  const {
    uploadAsset: upload,
    uploadingAssets: uploads,
  } = useUploadAssets('RichMediaInput')

  const {
    loadUrlPreview,
    urlPreviews,
  } = useUrlPreviews('RichMediaInput')

  const [, setUploadedMediaType] = useState(null)
  let media = useRef()
  {
    const newMedia = richMedia.parse(value) || {}
    for (const prop of ['files', 'text', 'urlPreview'])
      media[prop] = newMedia[prop]
  }

  const change = changes => {
    if (disabled) return
    Object.assign(media, changes)
    if (!media.text) delete media.text
    if (media.files && media.files.length === 0) delete media.files
    onChange(
      Object.keys(media).length === 0
        ? undefined
        : richMedia.toHTML(media)
    )
  }

  const setText = text => { change({ text }) }

  // whenever you add files you destroy any url preview you had
  const setFiles = files => {
    change({ files, urlPreview: undefined })
  }

  const removedPreviewUrls = useMemo(() => new Set(), [])
  const removeUrlPreview = () => {
    if (media.urlPreview){
      setUploadedMediaType(null)
      if (media.urlPreview) {
        removedPreviewUrls.add(media.urlPreview)
      }
      change({ urlPreview: undefined })
    }
  }


  useEffect(
    () => {
      // url previews are not used if you have attached files
      if (media.files && media.files.length > 0) return
      const urls = findUrls(media.text || '')
      // dont re-add a url thats preview was explicitly removed
      for(const url of urls) removedPreviewUrls.delete(url)
      if (urls.length === 0) return
      if (
        !media.urlPreview ||
        !urls.includes(media.urlPreview.url)
      ){
        const [url] = urls
        if (url in urlPreviews){
          change({ urlPreview: urlPreviews[url] })
        }else{
          change({ urlPreview: {loading: true, url} })
          loadUrlPreview(url)
        }
      }
    },
    [media.text]
  )

  // relace urlPreview placeholder value with loaded preview
  useEffect(
    () => {
      if (!media.urlPreview) return
      if (
        media.urlPreview &&
        media.urlPreview.loading &&
        media.urlPreview.url in urlPreviews
      ) change({ urlPreview: urlPreviews[media.urlPreview.url] })
    },
    [urlPreviews]
  )

  // remove old failed uploads when we load for the first time
  useEffect(
    () => {
      if (!media.files) return
      const failedUploads = media.files
        .filter(file => file.uploading && !uploads[file.uploading])
        .map(file => file.uploading)
      if (failedUploads.length === 0) return
      setFiles(
        media.files.map(file =>
          file.uploading
            ? {error: `upload failed`}
            : file
        )
      )
    },
    []
  )

  // anytime the state of any asset uploads change
  // attmpt to replace the placeholder with the
  // asset url
  useEffect(
    () => {
      if (!media.files) return
      const newFiles = media.files.map(file => {
        if (typeof file.uploading === 'undefined') return file
        const upload = uploads[file.uploading]
        if (!upload) return {error: `upload failed`}
        if (upload.loading) return file
        file = {...file, url: upload.url, uploading: undefined}
        if (upload.preview){
          const { height, width, name } = upload.preview
          file = {...file, height, width, name }
        }
        return file
      })
      setFiles(newFiles)
    },
    [uploads]
  )

  const uploadFiles = newFiles => {
    const allFiles = [...(media.files || [])]

    const hasExistingAudioOrVideo = allFiles.some(file => {
      const type = file.type.split('/')[0].toLowerCase()
      return type === 'audio' || type === 'video'
    })
    newFiles.forEach(file => {
      const type = file.type.split('/')[0].toLowerCase()
      if (!['image', 'video', 'audio'].includes(type)){
        console.warn(`upsupported file type ${file.type}`)
        return
      }
      if (type === 'audio' || type === 'video') {
        // If we already have audio/video, skip this file
        if (hasExistingAudioOrVideo) {
          console.warn('Only one audio or video file is allowed')
          return
        }

        // If there are other new audio/video files before this one, skip this file
        const hasNewAudioOrVideo = newFiles.some((f) => {
          const fType = f.type.split('/')[0].toLowerCase()
          return (fType === 'audio' || fType === 'video') &&
                 newFiles.indexOf(f) < newFiles.indexOf(file)
        })

        if (hasNewAudioOrVideo) {
          console.warn('Only one audio or video file is allowed')
          return
        }
      }

      const uploading = upload(file)
      allFiles.push({ uploading, type: file.type })
    })
    setFiles(allFiles)
  }
  const onFiles = uploadFiles
  // const accept = 'image/*,video/*,audio/*'

  const onPaste = event => {
    const paste = event.clipboardData || window.clipboardData
    if (paste.files.length === 0) return
    event.stopPropagation()
    event.preventDefault()
    onFiles([...paste.files])
  }

  const textEmpty = typeof media.text !== 'string' || media.text === ''
  const empty = richMedia.isEmpty(value)
  const textAreaStyle = PlainText.getStyle(
    textEmpty ? placeholder : media.text,
    { bigify }
  )
  textAreaStyle['--line-height'] = textAreaStyle.lineHeight

  const withFiles = !!media.files

  const inputRef = useRef()
  const focusInput = useCallback(
    () => {
      const input = inputRef.current.base.querySelector('textarea, *[contenteditable]')
      if (input) input.focus()
    },
    []
  )
  const hasAudioOrVideo = media.files?.some(file => {
    const type = file.type.split('/')[0].toLowerCase()
    return type === 'audio' || type === 'video'
  })

  return <FilesUploadDroparea {...{
    ...props,
    className: classNames('RichMediaInput', { className, empty, withFiles }),
    onFiles,
    accept: hasAudioOrVideo ? 'image/*' : 'image/*,video/*,audio/*',
  }}>
    <div className="RichMediaInput-rel">
      <div className="RichMediaInput-input">
        <TextArea {...{
          ref: inputRef,
          style: textAreaStyle,
          disabled,
          value: media.text,
          onInput: setText,
          resize: 'vertical',
          minRows: 1,
          maxRows: 4,
          autoResizeVertically: true,
          placeholder,
          onPaste,
        }}/>
      </div>
      <div className="RichMediaInput-footer">
        {/* Render the Image FileButton only if no media has been uploaded or if an image has been uploaded */}

        <FileButton
          multiple
          {...{
            onFiles,
            accept: hasAudioOrVideo ? 'image/*' : 'image/*,video/*,audio/*',
            title: hasAudioOrVideo ? 'Only one audio or video file is allowed' : undefined
          }}>
          <Icon size="lg" type="attachment" />
        </FileButton>


        <span onClick={focusInput} />
      </div>
    </div>
    {media.files &&
      <Files {...{ disabled, files: media.files, setFiles, uploads, setUploadedMediaType }} />
    }

    {media.urlPreview &&
      <RemoveableFeedPostURLPreview {...{
        removeUrlPreview,
        urlPreview:  media.urlPreview
      }}/>
    }
  </FilesUploadDroparea>
}

RichMediaInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  bigify: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
}

function Files({ disabled, files, setFiles, uploads, setUploadedMediaType }) {
  if (!files || files.length === 0) return

  const slides = files.map(file => {
    if (!file.uploading) return file
    const upload = uploads[file.uploading]
    const preview = upload && upload.preview
    const loading = !!(upload && upload.loading)
    const media = loading ? preview : file
    const type = media && media.type && media.type.split('/')[0].toLowerCase()
    return {
      ...media,
      type,
      uploading: loading,
      failed: file.error,
      progress: (upload && upload.progress),
    }
  })

  const onRemove = disabled ? null : file => {
    setFiles(files.filter(f => f !== file))
  }
  return <MediaSlideshow {...{ slides, onRemove, setUploadedMediaType }} />
}

function RemoveableFeedPostURLPreview({ removeUrlPreview, urlPreview }){
  return <div className="RichMediaInput-urlPreview">
    <RemoveButton onClick={removeUrlPreview}/>
    <FeedPostURLPreview {...urlPreview}/>  </div>
}

