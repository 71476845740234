import { Fragment, h } from 'preact'
import { memo, useState } from 'preact/compat'
import PropTypes from 'prop-types'
import Button from 'components/Button'

import FormBuilder from 'components/FormBuilder'
import Form from 'components/Form'
import { useAppState } from 'lib/appState'
import { createPageAlert } from 'resources/pageAlerts'
import './index.sass'

export default function OrganizationForumFeedPostDeleteRequestModal({
  className = '',
  post,
  ...props
}){
  return <div
    {...props}
    className={`OrganizationFeedPostDeleteRequestModal ${className}`}
  >
    <Contents {...{post}} />
  </div>
}

OrganizationForumFeedPostDeleteRequestModal.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
}

const Contents = memo(({ post }) => {

  const OPTIONS = [
    { value: 'deleteEntirely', label: 'Delete Entirely' },
    { value: 'deleteFromFeed', label: 'delete From Feed' },
  ]

  const {
    takeAction,
  } = useAppState({
    [`organizationFeedPost:${post.uid}:requestDeletePost`]: 'requestDeletePost',
    [`organizationFeedPost:${post.uid}:requestDeletePost:error`]: 'requestDeletePostError',
    [`organizationFeedPost:${post.uid}:requestDeletePost:success`]: 'requestDeletePostSuccess',
  }, 'OrganizationForumFeedPostDeleteRequestModal')


  const [formState, setFormState] = useState({ selectedOption: '', reason: '' })

  const handleOptionChange = (e) => {
    setFormState({ ...formState, selectedOption: e.target.value })
  }

  const handleReasonChange = (e) => {
    setFormState({ ...formState, reason: e.target.value })
  }


  const handleSubmit = () => {
    const { selectedOption, reason } = formState
    if((selectedOption === 'deleteEntirely' || selectedOption === 'deleteFromFeed')
     && reason) {
      takeAction('feedPosts.requestDeletePost', { post, selectedOption, reason })
      createPageAlert({
        message: "Deletion request sent to support successfully! We will get back to you as soon as possible",
        type: "success"
      })
    } else {
      createPageAlert({
        message: "Please select a request type and enter a reason",
        type: "error"
      })
    }
  }

  return (
    <FormBuilder {...{
      onSubmit: handleSubmit,
      className: 'OrganizationFeedPostDeleteRequestModal__form',
      render: form => (
        <Fragment>
          <Form.Row>
            {form.item({
              label: 'Options Type',
              input: (
                <select
                  name="options"
                  onChange={handleOptionChange}
                  className="OrganizationFeedPostDeleteRequestModal__select-field"
                  value={formState.selectedOption}
                >
                  <option hidden value="">Choose request type</option>
                  {OPTIONS.map(({ value, label }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </select>
              ),
              required: true,
            })}
          </Form.Row>
          <Form.Row>
            <textarea
              name="reason"
              className="OrganizationFeedPostDeleteRequestModal__reason-field"
              placeholder="Enter a reason for your request"
              onChange={handleReasonChange}
              value={formState.reason}
              required
            />
          </Form.Row>
          <Form.Row className={'OrganizationForumFeedPostDeleteModal__buttons'}>
            <Button
              type="primary"
              onClick={handleSubmit}
            >
              Send Request
            </Button>
          </Form.Row>
        </Fragment>
      ),
    }} />
  )
})
