import { Fragment, h } from 'preact'
import { useRef, useEffect, useMemo, useCallback, useState } from 'preact/hooks'
import isMobile from 'lib/isMobile'
import IconButton from 'components/IconButton'
import Loading from 'components/Loading'
import FilesUploadDroparea from 'components/FilesUploadDroparea'
import { ChatMessageForm } from './ChatMessageForm'
import { ChatMessages } from './ChatMessages'
import { ChatOptionsMenu } from 'components/ChatChannelListMember/ChatOptionsMenu'
import { useMyOrganizationMembership } from 'lib/membershipAppStateHooks'
import { useAppState } from 'lib/appState'
import './index.sass'
import ChatChip from 'components/HubChiplist'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import EndUserAvatarInlineDisplay from 'components/EndUserAvatarInlineDisplay'
import NotAMemberMessage from 'components/NotAMemberMessage'
import EndUserAvatar from 'components/EndUserAvatar'
import MentionsMenu from 'components/MentionsMenu'
import useForceUpdate from 'lib/useForceUpdate'
import moment from 'moment'
import Tooltip from 'components/Tooltip'


export function ChatWindow({
  organization,
  myPublicProfile,
  uid,
  loading,
  disabled,
  header,
  chatMessages,
  pendingChatMessages,
  markAllChatMessagesAsRead,
  createChatMessage,
  editChatMessage,
  replyToMessage,
  deleteChatMessage,
  dmMute,
  generalMute,
  isOtherMemberAdmin,
  undoDeleteChatMessage,
  createdAt,
  creatorUserDid,
  chatChannelUid,
  messageUid,
  isPersonalChatPage = true,
  synopsis,
  unread,
  starChatMessage,
  unstarChatMessage,
  isOneToOne,
  openStarredMessagesModal,
  closeStarredMessagesModal,
  replyingTo,
  setReplyingTo,
  isEditing,
  setIsEditing,
  editedMessage,
  setEditedMessage,
  editMessageUid,
  setEditMessageUid,
  chatChannelType,
  addReactionToMessage,
  handleRemoveReaction,
  setForwarding,
  setForwardMessageUid,
  chatChip,
  setForwardedMessageContent,
  forwardedMessageContent,
  channelUidsToForwardTo,
  forwardMessageUid,
  openforwardWindow,
  setOpenforwardWindow,
  setSelectedChats,
  setChatChip,
  openThreadRepliesModal,
  closeThreadRepliesModal,
  isThread,
  isEditingThread,
  setIsEditingThread,
  openChatSearchModal,
  closeChatSearchModal,
  chatChannel,
  isGroupEditModalOpen,
  handleOpenGroupEditModal,
  setShowManageChatMembersModal,
  showManageChatMembersModal,
  deleteChatChannel,
  leaveChannel,
  handleOpenAssignAdminModal,
  openChatInfoPanel,
  toggleChatSelection,
  markChatMessageAsRead,
}) {
  const memberUserDids = chatChannel?.memberUserDids
  const { isAdmin, isCurator } = useMyOrganizationMembership(
    organization.apikey,
    "OrganizationChatPage"
  )
  const chatMessageFormRef = useRef()
  const textAreaRef = useRef()

  const { starredChatMessages } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:starredMessages`] : 'starredChatMessages'
    },
    'OrganizationChatPage'
  )

  const [isUnreadMessages, setIsUnreadMessages] = useState(false)
  const forceUpdate = useForceUpdate()

  const allChatMessagesWithReplies = chatMessages?.reduce((acc, message) => {
    if (message.replies) {
      return [...acc, message, ...message.replies]
    }
    return [...acc, message]
  }, [])

  const allChatMessages = allChatMessagesWithReplies?.map(message => {
    return {
      ...message,
      fromMe: message.creatorUserDid === myPublicProfile.did,
    }
  })

  const handleMarkAllAsRead = useCallback(() => {
    markAllChatMessagesAsRead()

    allChatMessages?.forEach(item => {
      if (!item.readAt && !item.fromMe) {
        item.readAt = moment().toISOString()
      }
    })
    forceUpdate()
    setIsUnreadMessages(false)
  }, [markAllChatMessagesAsRead, allChatMessages, forceUpdate])

  useEffect(() => {
    const unreadMessages = allChatMessages?.some(item => !item.readAt && !item.fromMe)
    setIsUnreadMessages(unreadMessages)
  }, [allChatMessages])


  const forwardHeader = <ChatChip chats={chatChip} onDelete={toggleChatSelection} />
  const [starredMessagesIds, setStarredMessagesIds] = useState(new Set())

  const otherMemberDids = new Set(memberUserDids)
  otherMemberDids.delete(myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(
    [...otherMemberDids],
    "OrganizationChatPage"
  )

  useEffect(() => {
    setStarredMessagesIds(new Set(starredChatMessages?.map(({ uid }) => uid) || []))
  }, [starredChatMessages])


  const handleStarMessage = useCallback((uid) => {
    const newIds = new Set([...starredMessagesIds, uid])
    setStarredMessagesIds(newIds)
    starChatMessage(chatChannelUid, uid)
  }, [starredMessagesIds, starChatMessage, chatChannelUid])

  const handleUnStarMessage = useCallback((uid) => {
    const newIds = new Set([...starredMessagesIds])
    newIds.delete(uid)
    setStarredMessagesIds(newIds)
    unstarChatMessage(chatChannelUid, uid)
  }, [starredMessagesIds, unstarChatMessage, chatChannelUid])

  const handleEditMessage = useCallback((uid, message, isDeletedByAdmin, editedMessage) => {
    setIsEditing(true)
    setIsEditingThread(false)
    setEditedMessage(editedMessage || message)
    setEditMessageUid(uid)
    setIsMessageDeletedByAdmin(isDeletedByAdmin)
  }, [])

  const [isMessageDeletedByAdmin, setIsMessageDeletedByAdmin] = useState(false)


  const handleDeleteMessage = useCallback(async (messageUid, deletionReason = "",  AdminComment = "",) => {
    try {
      await deleteChatMessage(chatChannelUid, messageUid, deletionReason, AdminComment)
    } catch (error) {
      console.error('Error deleting chat message:', error)
    }
  }, [deleteChatMessage, chatChannelUid])


  const handleUndoDeleteClick = useCallback(async (chatChannelUid, messageUid, action) => {
    try {
      await undoDeleteChatMessage(chatChannelUid, messageUid, action)
    } catch (error) {
      console.error(`Error processing undelete request with UID: ${messageUid}`, error)
    }
  }, [undoDeleteChatMessage, chatChannelUid])

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false)
    setIsEditingThread(false)
    setEditedMessage('')
    setEditMessageUid(null)
  }, [])


  const focusInput = useCallback(
    () => {
      if (isMobile) return
      const start = Date.now()
      const stop = () => { clearInterval(intervalId) }
      const focus = () => {
        if (Date.now() - start > 1000) return stop()
        if (!chatMessageFormRef.current) return
        const input = chatMessageFormRef.current.base.querySelector('textarea')
        if (!input) return
        const { activeElement } = global.document
        if (activeElement === input) return stop()
        input.focus()
      }
      const intervalId = setInterval(focus, 100)
    },
    []
  )

  const uploadFiles = useCallback(
    async (filesWithCaptions) => {
      for (const fileData of filesWithCaptions) {
        await createChatMessage({
          file: fileData.file,
          caption: fileData.caption,
        })
      }
      focusInput()
    },
    [createChatMessage],
  )

  const href = window.location.href

  useEffect(focusInput, [!!chatMessages])


  const currentUserDid = myPublicProfile.did

  const hasLeftChannel = useMemo(() => {
    return !!chatChannel.leftAt
  }, [chatChannel.events, currentUserDid, synopsis])
  const firstThreeMemberProfiles = publicProfiles
    .filter(profile => otherMemberDids.has(profile.did))
    .slice(0, 3)

  const isDirectChat = chatChannel.memberUserDids?.length === 2 && !chatChannel.isGroupChat
  const otherMemberProfile = publicProfiles.find(profile => myPublicProfile.did !== profile.did)
  const userChatName = otherMemberProfile?.username || otherMemberProfile?.displayName

  const isChannelNameIsDM = chatChannel.name === 'unnamed chat'

  const chatName = isDirectChat && isChannelNameIsDM ? userChatName : chatChannel.name

  const getAdditionalMembersCount = () => {
    const additionalCount = chatChannel.memberUserDids?.length - 3
    return additionalCount > 0 ? `+ ${additionalCount} more` : ''
  }

  const [mentionStr, setMentionStr] = useState('')
  const [showMentionDropDown, setShowMentionDropDown] = useState(false)
  const [mentionableProfiles, setMentionableProfiles] = useState([])
  const [selectedMentions, setSelectedMentions] = useState([])
  const [inputStr, setInputStr] = useState('')

  const filterMentionableProfiles = (mentionStr) => {
    if (!mentionStr) {
      setMentionableProfiles(
        publicProfiles.filter(profile => !selectedMentions.some(selected => selected.did === profile.did))
      )
    } else {
      const filteredProfiles = publicProfiles.filter(profile => {
        const displayName = profile.displayName.toLowerCase()
        const username = profile.username.toLowerCase()
        return (displayName.includes(mentionStr.toLowerCase()) ||
                username.includes(mentionStr.toLowerCase())) &&
              !selectedMentions.some(selected => selected.did === profile.did)
      })
      setMentionableProfiles(filteredProfiles)
    }
  }

  const findMentions = (inputStr) => {
    const mentionRegex = /(?:^|\s)@(?!\s)(\w*)/g
    const detectedMentions = inputStr.match(mentionRegex)

    if (detectedMentions) {
      const lastMention = detectedMentions[detectedMentions.length - 1].trim()
      const mentionStr = lastMention.slice(1)

      setMentionStr(mentionStr)
      setShowMentionDropDown(true)
      filterMentionableProfiles(mentionStr)
    } else {
      setShowMentionDropDown(false)
      setMentionStr('')
    }
  }

  const handleInput = (currentText) => {
    setInputStr(currentText)
    findMentions(currentText)
  }

  const handleMentionSelect = (mention) => {
    const mentionText = `@${mention.displayName || mention.username}`
    const currentText = inputStr
    const textAreaElement = textAreaRef.current.textarea

    const cursorPosition = textAreaElement.selectionStart
    const lastAtIndex = currentText.lastIndexOf('@', cursorPosition)
    const preMentionText = currentText.slice(0, lastAtIndex)
    const postMentionText = currentText.slice(cursorPosition)

    const newText = `${preMentionText}${mentionText} ${postMentionText}`

    setInputStr(newText)
    setSelectedMentions([...selectedMentions, mention])
    setShowMentionDropDown(false)

    setTimeout(() => {
      const newCursorPosition = preMentionText.length + mentionText.length + 1
      textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition)
      textAreaElement.focus()
    }, 0)
  }

  const handleTextUpdate = () => {
    let updatedText = inputStr
    selectedMentions.forEach(mention => {
      const mentionText = `@${mention.displayName || mention.username}`
      if (!updatedText.includes(mentionText)) {
        updatedText = updatedText.replace(new RegExp(`<span class="mention">${mentionText}</span>`, 'g'), mentionText)
        setSelectedMentions(selectedMentions.filter(m => m !== mention))
      }
    })
    setInputStr(updatedText)
  }

  useEffect(() => {
    if (mentionStr) {
      filterMentionableProfiles(mentionStr)
    }
  }, [mentionStr])

  useEffect(() => {
    handleTextUpdate()
  }, [inputStr])

  if (loading){
    return <Loading type="block" />

  }

  return <FilesUploadDroparea
    className="OrganizationChatPage-ChatWindow"
    onFiles={uploadFiles}
    disabled={disabled}
  >
    {isUnreadMessages && <Tooltip
      {...{
        text: 'Mark all as read',
      }}
    >
      <IconButton
        key={"markAllAsReadButton"}
        className="OrganizationChatPage-markAllAsReadButton"
        onClick={handleMarkAllAsRead}
        type="read"
        size="lg"
        data-hidden={isUnreadMessages}
      />
    </Tooltip>}
    <div className="OrganizationChatPage-header">
      <div className={'OrganizationChatPage-header-elements'}>
        {isDirectChat ? (
          <Fragment>
            <div className={'OrganizationChatPage-header-element'}>
              <EndUserAvatar {...{
                publicProfile: otherMemberProfile,
                size: 'sm',
                onClick: openChatInfoPanel
              }} />
            </div>
            <div className={'OrganizationChatPage-header-element'}>
              {openforwardWindow ? forwardHeader : chatName}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={'OrganizationChatPage-header-element'}>
              <IconButton
                type="back"
                href={href.replace(/\/chat\/[^/]+$/, '/chat')}
                className="OrganizationChatPage-backButton" />
              <Fragment>
                {openforwardWindow ? forwardHeader : header}
              </Fragment>
            </div>
            <div className={'OrganizationChatPage-header-element'} onClick={openChatInfoPanel}>
              <EndUserAvatarInlineDisplay
                size="tn"
                publicProfiles={firstThreeMemberProfiles}
              />
              {getAdditionalMembersCount() && (
                <span className="additionalMembersCount">
                  {getAdditionalMembersCount()}
                </span>
              )}
            </div>
          </Fragment>
        )}
      </div>
      <div className={'OrganizationChatPage-header-items'}>
        <div className={'OrganizationChatPage-header-items-search'}>
          <IconButton
            type="search"
            onClick={() => {
              openChatSearchModal()
            }}
          />
        </div>
        {isPersonalChatPage && (
          <div className={'OrganizationChatPage-header-actions'}>
            <ChatOptionsMenu {...{
              synopsis,
              unread,
              chatChannel: chatChannelUid,
              isChatWindow: true,
              openStarredMessagesModal,
              closeChatSearchModal,
              closeThreadRepliesModal,
              myPublicProfile,
              isGroupEditModalOpen,
              handleOpenGroupEditModal,
              setShowManageChatMembersModal,
              showManageChatMembersModal,
              deleteChatChannel,
              leaveChannel,
              chatChannelDetails: chatChannel,
              handleOpenAssignAdminModal,
            }} />
          </div>
        )}
      </div>
    </div>
    {chatMessages
      ? <Fragment>
        <div className="OrganizationChatPage-ChatWindow-messages">
          {!openforwardWindow &&  <ChatMessages {...{
            chatMessages,
            pendingChatMessages,
            myPublicProfile,
            focusInput,
            markAllChatMessagesAsRead,
            createdAt,
            creatorUserDid,
            chatChannelUid,
            messageUid,
            editChatMessage,
            replyToMessage,
            handleDeleteMessage,
            handleUndoDeleteClick,
            replyingTo,
            setReplyingTo,
            handleEditMessage,
            starredMessagesIds,
            starChatMessage,
            unstarChatMessage,
            handleStarMessage,
            handleUnStarMessage,
            isOneToOne,
            isAdmin,
            isCurator,
            organization,
            chatChannelType,
            addReactionToMessage,
            handleRemoveReaction,
            setForwarding,
            setForwardMessageUid,
            setForwardedMessageContent,
            openThreadRepliesModal,
            closeStarredMessagesModal,
            isThread,
            chatChannel,
            memberUserDids,
            markChatMessageAsRead,
          }} />}
        </div>
        {isEditing && !isEditingThread && !hasLeftChannel ? (
          <Fragment>
            <ChatMessageForm
              isEditing
              editedMessage={editedMessage}
              handleEditMessage={setEditedMessage}
              handleCancelEdit={handleCancelEdit}
              isDeletedByAdmin={isMessageDeletedByAdmin}
              closeStarredMessagesModal={closeStarredMessagesModal}
              editChatMessage={editChatMessage}
              setIsEditing={setIsEditing}
              setIsEditingThread={setIsEditingThread}
              setEditedMessage={setEditedMessage}
              setEditMessageUid={setEditMessageUid}
              editMessageUid={editMessageUid}
              chatChannelUid={chatChannelUid}
              textAreaRef={textAreaRef}
              inputStr={inputStr}
              setInputStr={setInputStr}
              handleInput={handleInput}
              setShowMentionDropDown={setShowMentionDropDown}
              setMentionableProfiles={setMentionableProfiles}
              setSelectedMentions={setSelectedMentions}
              publicProfiles={publicProfiles}
            />
            {showMentionDropDown && mentionableProfiles?.length > 0 && (
              <MentionsMenu {...{
                mentions: mentionableProfiles,
                onClick: handleMentionSelect
              }} />
            )}
          </Fragment>
        ) : !hasLeftChannel ? (
          <Fragment>
            <ChatMessageForm
              {...{
                ref: chatMessageFormRef,
                uid,
                createChatMessage,
                uploadFiles,
                disabled: disabled || !chatMessages,
                replyingTo,
                setReplyingTo,
                replyToMessage,
                chatChannelUid,
                dmMute,
                generalMute,
                isOtherMemberAdmin,
                forwardedMessageContent,
                forwardMessageUid,
                channelUidsToForwardTo,
                openforwardWindow,
                setOpenforwardWindow,
                setSelectedChats,
                setChatChip,
                setForwarding,
                closeStarredMessagesModal,
                hasLeftChannel,
                selectedMentions,
                mentionStr,
                otherMemberProfile,
                textAreaRef,
                inputStr,
                setInputStr,
                handleInput,
                setShowMentionDropDown,
                setMentionableProfiles,
                setSelectedMentions,
                publicProfiles
              }} />
            {showMentionDropDown && mentionableProfiles?.length > 0 && (
              <MentionsMenu {...{
                mentions: mentionableProfiles,
                onClick: handleMentionSelect,
              }} />
            )}
          </Fragment>
        ) : (
          <NotAMemberMessage />
        )}
      </Fragment>
      : <Loading type="block" delayed />}
  </FilesUploadDroparea>
}
