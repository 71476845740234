import { h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import PropTypes from "prop-types"
import StyleishModal from "components/StyleishModal"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import PeopleList from "components/PeopleList"
import {
  usePublicProfiles,
} from "lib/membershipAppStateHooks"
import {
  useRemoveMembersFromChatChannel,
  useAssignChatChannelAdminRole,
  useRemoveChatChannelAdminRole,
  useChatChannelMembershipDetails,
  useChatChannel,
} from "lib/chatHooks"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import IconButton from "components/IconButton"
import SearchInput from 'components/SearchInput'
import "./index.sass"


export default function ManageChatMembersModal({
  organizationApikey,
  open,
  onClose,
  chatChannel: chatChannelUid,
  handleOpenAddChatMembersModal,
  ...props
}) {

  const {
    chatChannel,
  } = useChatChannel(chatChannelUid, 'ManageChatMembersModal')

  const [activeDropdown, setActiveDropdown] = useState(null)

  const [searchTerm, setSearchTerm] = useState("")
  const [membershipDetails, setMembershipDetails] = useState(null)

  const dropdownRef = useRef(null)

  const memberUserDids = chatChannel?.memberUserDids || []
  const { publicProfiles } = usePublicProfiles([...memberUserDids], "ManageChatMembersModal")
  const assignChatChannelAdminRole = useAssignChatChannelAdminRole("ManageMembersModal")
  const removeChatChannelAdminRole = useRemoveChatChannelAdminRole("ManageMembersModal")
  const removeMembersFromChatChannel = useRemoveMembersFromChatChannel("ManageMembersModal")
  const {
    loadingChatChannelMembershipDetails,
    errorLoadingChatChannelMembershipDetails,
    loadChatChannelMembershipDetails
  } = useChatChannelMembershipDetails(chatChannelUid, "ManageMembersModal")


  useEffect(() => {
    if (chatChannelUid) {
      loadChatChannelMembershipDetails(chatChannelUid)
        .then(details => {
          setMembershipDetails(details)
        })
        .catch(error => {
          console.error("Error loading membership details:", error)
        })
    }
  }, [chatChannelUid, loadChatChannelMembershipDetails])


  if (loadingChatChannelMembershipDetails) {
    return <div>Loading membership details...</div>
  }

  if (errorLoadingChatChannelMembershipDetails) {
    return <div>Error loading membership details: {errorLoadingChatChannelMembershipDetails}</div>
  }

  const updateMembershipDetails = (chatChannelUid) => {
    loadChatChannelMembershipDetails(chatChannelUid)
      .then(details => {
        setMembershipDetails(details)
      })
      .catch(error => {
        console.error("Error loading updated membership details:", error)
      })
  }

  const handleRemoveAdmin = (event, memberDid) => {
    event.stopPropagation()
    removeChatChannelAdminRole(chatChannelUid, memberDid).then(() => {
      updateMembershipDetails(chatChannelUid)
    }).catch(error => {
      console.error('Failed to remove admin role:', error)
    })
  }

  const handleRemoveFromGroup = (event, memberDid) => {
    event.stopPropagation()

    // eslint-disable-next-line no-alert
    const confirmed = confirm("You are removing this member from the group.")

    if (confirmed) {
      removeMembersFromChatChannel(chatChannelUid, [memberDid])
        .then(() => {

          setActiveDropdown(null)
        })
        .catch((error) => {
          console.error('Failed to remove member:', error)
        })
    }
  }


  const handleMakeAdmin = (event, memberDid) => {
    event.stopPropagation()
    assignChatChannelAdminRole(chatChannelUid, memberDid).then(() => {
      updateMembershipDetails(chatChannelUid)
    }).catch(error => {
      console.error('Failed to remove admin role:', error)
    })
  }


  const handleToggleDropdown = (did) => {
    setActiveDropdown(activeDropdown === did ? null : did)
  }


  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div className="ManageChatMembersModal">
            <div className="ManageChatMembersModal-header">
              <IconButton
                type="return"
                onClick={onClose}
                className="ManageChatMembersModal-header-returnButton"
              />
              <h2 className="ManageChatMembersModal-header-title">
                Manage Members
              </h2>

              <IconButton
                {...{
                  type: "create",
                  onClick: handleOpenAddChatMembersModal,
                }}
                style={{ paddingLeft: "69px" }}
              />
            </div>

            <SearchInput {...{
              placeholder: 'Search…',
              className: 'ManageChatMembersModal-SearchBar',
              interfaceHelp: {
                position: 'tl',
                inside: true,
                title: 'Search',
                content: `Search Members`,
              },
              value: searchTerm,
              onChange: setSearchTerm,
            }
            }
            />

            <div className="ManageChatMembersModal-content">
              <ManageMembersForm
                {...{
                  ...props,
                  organizationApikey,
                  onClose,
                  handleMakeAdmin,
                  handleRemoveAdmin,
                  handleRemoveFromGroup,
                  activeDropdown,
                  setActiveDropdown,
                  chatChannel,
                  chatChannelMembershipDetails: membershipDetails,
                  memberUserDids,
                  searchTerm,
                  publicProfiles: publicProfiles,
                  handleToggleDropdown: handleToggleDropdown,
                  dropdownRef,
                }}
              />
            </div>
            <div className="ManageChatMembersModal-actions">
              <ButtonRow>
                <Button type="primary" value="Close" onClick={onClose} />
              </ButtonRow>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}


ManageChatMembersModal.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
  onAssignAdmin: PropTypes.func.isRequired,
  onLeaveGroup: PropTypes.func.isRequired,
  onAddMember: PropTypes.func.isRequired,
  handleRemoveMember: PropTypes.func.isRequired,
  handleAssignAdmin: PropTypes.func.isRequired,
  chatChannel: PropTypes.string.isRequired,
  handleOpenAddChatMembersModal: PropTypes.func.isRequired,

}

function ManageMembersForm({
  handleMakeAdmin,
  handleRemoveAdmin,
  handleRemoveFromGroup,
  activeDropdown,
  setActiveDropdown,
  chatChannelMembershipDetails,
  searchTerm,
  publicProfiles,
  handleToggleDropdown,
  dropdownRef,

}) {
  const isUserAdmin = (memberDid) => {
    return chatChannelMembershipDetails?.memberships?.some(membership =>
      membership.memberUserDid === memberDid && membership.isAdmin
    )
  }

  const filteredProfiles = publicProfiles.filter(profile =>
    profile.displayName?.toLowerCase().includes(searchTerm?.toLowerCase())
  )

  const people = filteredProfiles.map((publicProfile) => {


    const isAdmin = chatChannelMembershipDetails && isUserAdmin(publicProfile.did)

    return {
      ...publicProfile,
      className: "AssignAdminModal-recipient",
      isAdmin: isAdmin && true,
      buttons: (
        <div className="dropdown-container" >
          <svg
            onClick={() => handleToggleDropdown(publicProfile.did)}

            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu-vertical-dots"
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
          {activeDropdown === publicProfile.did && (
            <ul className="dropdown-menu">
              <li onClick={(event) => handleRemoveFromGroup(event, publicProfile.did)}>
                Remove from Group
              </li>
              {isAdmin ? (
                <li onClick={(event) => handleRemoveAdmin(event, publicProfile.did)}>
                  Remove Admin
                </li>
              ) : (
                <li onClick={(event) => handleMakeAdmin(event, publicProfile.did)}>
                  Make Admin
                </li>
              )}
            </ul>
          )}
        </div>
      ),
    }
  })


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  return (
    <div className="AssignAdminModal-form">
      <PeopleList {...{ people, newWindow: true }} />

      {people.map(person => (
        <div key={person.did}>
          {person.dropdown}
        </div>
      ))}
    </div>
  )
}

