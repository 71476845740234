import { Fragment, h } from 'preact'
import { useRef, useEffect, useCallback, useMemo, useState } from 'preact/hooks'
import moment from 'moment'
// import usePageHasFocus from 'lib/usePageHasFocusHook'
import useOnResize from 'lib/useOnResizeHook'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import useUploadAssets from 'lib/uploadAssetsHook'
import useOnDOMChange from 'lib/useOnDOMChangeHook'
import useForceUpdate from 'lib/useForceUpdate'
import { publicProfileToDisplayName } from 'lib/publicProfiles'
import IconButton from 'components/IconButton'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import Timestamp from 'components/Timestamp'
import { isScrolledToBottom } from './isScrolledToBottom'
import { callAFewMoreTimes } from './callAFewMoreTimes'
import { DayBreak } from './DayBreak'
import { ChatMessage } from './ChatMessage'
import './index.sass'


export function ChatMessages({
  myPublicProfile,
  chatMessages = [],
  pendingChatMessages = [],
  focusInput,
  error,
  // markAllChatMessagesAsRead,
  createdAt,
  creatorUserDid,
  chatChannelUid,
  editChatMessage,
  replyToMessage,
  handleDeleteMessage,
  handleUndoDeleteClick,
  replyingTo,
  setReplyingTo,
  handleEditMessage,
  fromMe,
  handleStarMessage,
  handleUnStarMessage,
  messageRef,
  isOneToOne,
  isAdmin,
  isCurator,
  organization,
  chatChannelType,
  addReactionToMessage,
  handleRemoveReaction,
  setForwarding,
  setForwardMessageUid,
  setForwardedMessageContent,
  chatChannel,
  openThreadRepliesModal,
  isThread,
  closeStarredMessagesModal,
  starredMessagesIds,
  memberUserDids,
  markChatMessageAsRead,
}) {
  const eventDids = new Set(
    chatChannel.events
      ?.flatMap((event) => [event.actorUserDid, event.targetUserDid])
  )
  const { publicProfiles: eventPublicProfiles } = usePublicProfiles(
    eventDids,
    "ChatMessages"
  )

  const { uploadingAssets } = useUploadAssets('ChatMessages')
  const [isUngrouped, setIsUngrouped] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState('')

  const { publicProfiles } = usePublicProfiles(memberUserDids, 'ChatMessages')
  const creatorPublicProfile = publicProfiles.find(pp => pp.did === creatorUserDid)

  const isImageMessage = (message) => {
    return message?.startsWith('jlinc:dm:attachment') && message.includes('image')
  }

  const allItemsSorted = useMemo(() => {
    const allItems = [
      ...chatMessages,
      ...pendingChatMessages.map(pcm => ({
        ...pcm,
        creatorUserDid: myPublicProfile.did,
        pending: true,
      })),
      ...chatChannel.events?.map(event => ({ ...event, isEvent: true })) || [],
    ].map(item => ({
      ...item,
      createdAtMoment: moment(item.createdAt),
      fromMe: item.creatorUserDid === myPublicProfile.did,
      unread: !fromMe && !item.readAt,
      publicProfile: item.creatorUserDid === myPublicProfile.did ?
        myPublicProfile : publicProfiles.find(p => p.did === item.creatorUserDid),
      upload: item.uploadId ? uploadingAssets[item.uploadId] : undefined,
      timestamp:  moment(createdAt).format(Timestamp.formats.full),
      day:  moment(createdAt).format('LL')

    }))

    return allItems.sort((a, b) => a.createdAtMoment.diff(b.createdAtMoment))
  }, [chatMessages, pendingChatMessages,
    chatChannel.events, myPublicProfile,
    publicProfiles, uploadingAssets])


  const imageMessageGroups = useMemo(() => {
    const groups = {}

    allItemsSorted.forEach((message) => {
      const isImage = isImageMessage(message.message)
      const groupId = message.groupId

      if (isImage && groupId) {
        if (!groups[groupId]) {
          groups[groupId] = []
        }
        groups[groupId].push(message)
      }
    })

    if (isUngrouped && selectedGroupId) {
      const filteredGroups = Object.entries(groups).reduce((acc, [groupId, messages]) => {
        if (groupId === selectedGroupId) {
          acc.push(...messages.map(message => [message]))
        } else {
          acc.push(messages)
        }
        return acc
      }, [])

      return filteredGroups
    } else {
      return Object.values(groups)
    }

  }, [chatMessages, isImageMessage, isUngrouped, selectedGroupId])

  const toggleGrouping = (groupId) => {
    setSelectedGroupId(groupId)
    setIsUngrouped(prevState => !prevState)
  }
  const generateEventMessage = (eventType, actorUserDid, targetUserDid, newValue) => {
    const actorDisplayName = publicProfileToDisplayName(
      eventPublicProfiles.find((pp) => pp.did === actorUserDid)
    )
    const targetDisplayName = publicProfileToDisplayName(
      eventPublicProfiles.find((pp) => pp.did === targetUserDid)
    )

    switch (eventType) {
    case "add_member":
      return `${actorDisplayName} added ${targetDisplayName} to the chat.`
    case "remove_member":
      return `${actorDisplayName} removed ${targetDisplayName} from the chat.`
    case "add_admin":
      return `${actorDisplayName} granted admin rights to ${targetDisplayName}.`
    case "remove_admin":
      return `${actorDisplayName} revoked admin rights from ${targetDisplayName}.`
    case "left":
      return `${actorDisplayName} left this chat.`
    case "updated_name":
      return `${actorDisplayName} updated chat name to ${newValue}.`
    case "updated_description":
      return `${actorDisplayName} updated chat description to ${newValue}.`
    case "updated_picture":
      return `${actorDisplayName} updated chat picture.`
    case "updated_info":
      return `${actorDisplayName} updated chat info.`
    default:
      return `${actorDisplayName} updated chat details.`
    }
  }
  const children = useMemo(() => {
    const children = []
    const handledGroupIDs = new Set()
    if (creatorPublicProfile && createdAt && !isThread) children.push(
      <Header key={'createdBy'} subtle italic centered className="OrganizationChatPage-createdBy">
        <span>created by </span>
        <span>{publicProfileToDisplayName(creatorPublicProfile)} </span>
        <span>on <Timestamp time={createdAt} format="date" /> </span>
        <span>at <Timestamp time={createdAt} format="time" /></span>
      </Header>
    )
    allItemsSorted.forEach((item, index, array) => {
      const prevItem = array[index - 1]
      if (!prevItem || item.createdAtMoment.format('LL') !== prevItem.createdAtMoment.format('LL')) {
        children.push(
          <Fragment key={`daybreak-${item.createdAt}`}>
            { !isThread && <DayBreak day={item.createdAtMoment.format('LL')} />
            }
          </Fragment>
        )
      }
      const fromMe = item.creatorUserDid === myPublicProfile.did

      const upload = item.uploadId ? uploadingAssets[item.uploadId] : undefined

      if(item.isEvent && isThread) return

      if (item.isEvent) {

        const eventMessage = generateEventMessage(item.eventType,
          item.actorUserDid, item.targetUserDid, item.newValue)
        children.push(
          <Header subtle italic centered className="OrganizationChatPage-eventMessage" key={`event-${item.createdAt}`}>
            <span>{eventMessage}</span>
            <span> <Timestamp time={item.createdAt} format="date" /></span>
            <span> at <Timestamp time={item.createdAt} format="time" /></span>
          </Header>
        )
      } else {

        const isOther = (m) => !m || m.creatorUserDid !== item.creatorUserDid
        const firstInGroup = index === 0 || isOther(array[index - 1])
        const lastInGroup = index === array.length - 1 || isOther(array[index + 1])
        const groupIndex = imageMessageGroups.findIndex(group => group[0]?.uid === item.uid)
        handledGroupIDs.add(item.uid)
        const group = imageMessageGroups[groupIndex]
        const props = {
          ...item,
          key: item.uid,
          chatChannelUid,
          firstInGroup,
          lastInGroup,
          editChatMessage,
          replyToMessage,
          handleDeleteMessage,
          handleUndoDeleteClick,
          replyingTo,
          setReplyingTo,
          handleEditMessage,
          handleStarMessage,
          handleUnStarMessage,
          starredMessagesIds,
          messageRef,
          isOneToOne,
          publicProfiles,
          isAdmin,
          isCurator,
          organization,
          chatChannelType,
          addReactionToMessage,
          handleRemoveReaction,
          setForwarding,
          setForwardMessageUid,
          setForwardedMessageContent,
          messageUid: item.uid,
          fromMe,
          myPublicProfile,
          upload,
          replies: item.replies || [],
          openThreadRepliesModal,
          isThread,
          sendToMainChat: item.sendToMainChat || false,
          replyToMessageUid: item.replyToMessageUid || null,
          starredAt: item.starredAt || null,
          closeStarredMessagesModal,
          group,
          onToggleGrouping: toggleGrouping,
          messagesLength: chatMessages.length,
          messagesIndex: index,
          markChatMessageAsRead,
        }

        children.push(<ChatMessage {...props} key={item.uid} />)

        return <ChatMessage {...props} key={item.uid} />
      }
    })
    return children
  }, [
    allItemsSorted,
    editChatMessage,
    replyToMessage,
    handleDeleteMessage,
    handleUndoDeleteClick,
    replyingTo,
    setReplyingTo,
    handleEditMessage,
    handleStarMessage,
    handleUnStarMessage,
    addReactionToMessage,
    handleRemoveReaction,
    chatChannelUid,
    isAdmin,
    isCurator,
    chatChannelType,
    organization,
    publicProfiles,
    myPublicProfile,
    messageRef,
    isOneToOne,
    uploadingAssets,
    toggleGrouping,
  ])


  // const pageHasFocus = usePageHasFocus()
  const listRef = useRef()
  const forceUpdate = useForceUpdate()
  const isStuckToBottomRef = useRef(true)

  const onScroll = useCallback(
    () => {
      const isStuckToBottom = isScrolledToBottom(listRef.current)
      if (isStuckToBottomRef.current === isStuckToBottom) return
      isStuckToBottomRef.current = isStuckToBottom
      forceUpdate()
    },
    []
  )


  const scrollToBottom = useCallback(
    smooth => {
      const list = listRef.current
      if (!list) return
      const top = list.scrollHeight - list.offsetHeight
      if (smooth) {
        list.scrollTo({ top, behavior: 'smooth' })
      } else {
        list.scrollTop = top
      }
      isStuckToBottomRef.current = true
    },
    []
  )
  useEffect(
    () => {
      const list = listRef.current
      if (!list) return
      const hasMedia = list.querySelectorAll('.OrganizationChatPage-FileAttachment').length > 0
      const scrollToBottomAndMakeVisible = () => {
        scrollToBottom()
        list.style.visibility = 'visible'
      }
      scrollToBottomAndMakeVisible()
      if (hasMedia) callAFewMoreTimes(scrollToBottomAndMakeVisible)
    },
    []
  )

  useOnResize(
    listRef,
    () => { if (isStuckToBottomRef.current) scrollToBottom() },
    []
  )

  useOnDOMChange({
    ref: listRef,
    onDOMChange() {
      if (isStuckToBottomRef.current) scrollToBottom()
    },
  })

  useEffect(
    () => {
      const list = listRef.current
      if (!list) return
      const latestMessage = chatMessages[chatMessages.length - 1]
      const latestMessageIsFromMe = latestMessage && latestMessage.pending
      if (!latestMessageIsFromMe) return
      focusInput()
      scrollToBottom(isStuckToBottomRef.current)
      callAFewMoreTimes(scrollToBottom)
    },
    [chatMessages.length]
  )

  // useEffect(
  //   () => {
  //     if (pageHasFocus && isStuckToBottomRef.current && chatMessages.length > 0)
  //       markAllChatMessagesAsRead()
  //   },
  //   [pageHasFocus, chatMessages.length]
  // )

  const onDownButtonClick = useCallback(
    () => {
      scrollToBottom(true)
      focusInput()
    },
    [scrollToBottom, focusInput]
  )

  useEffect(() => {
    if (listRef.current) {
      const element = listRef.current
      const scrollHeight = element.scrollHeight
      const height = element.clientHeight
      element.scrollTop = scrollHeight - height - 10
    }
  }, [])

  return <div className="OrganizationChatPage-ChatMessages">
    {!isStuckToBottomRef.current && <IconButton
      key="scrollDownButton"
      className="OrganizationChatPage-scrollDownButton"
      onClick={onDownButtonClick}
      type="angle-circled-down"
      size="lg"
      data-hidden={isStuckToBottomRef.current} />}
    <div
      ref={listRef}
      className="OrganizationChatPage-ChatMessages-list"
      onScroll={onScroll}
      id={'ChatMessages-list'}
    >
      <div>
        {children}
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  </div>
}
